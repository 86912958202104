import { useState, useEffect, useRef } from "react";
import "./BookingDetailsSliderNew.css";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  PlainCancelButton,
  SettleBillButton,
} from "../../UI/Buttons";
import UnderscoreRemoveCapitalize from "../UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../../assets/toastUtils";
import { showSuccessToast } from "../../../assets/toastUtils";
import VerticalTextAreaCustom from "../VerticalTextAreaCustom";
import { Button, Icon, Tooltip } from "@shopify/polaris";
import DeletePopupWithReasonBooking from "../DeletePopup/DeletePopupWithReasonBooking";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import BookingVoucher from "../BookingBillsVouchers/BookingVoucher";
import {
  formatAMPM,
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
  useCurrentTime,
} from "../../../utils/utils";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import { useNavigate } from "react-router-dom";
import HandleNaN from "../HandleNaN/HandleNaN";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import ModifyReservationPopup from "../ModifyReservationPopup/ModifyReservationPopup";
import CreateBookingTableComponent from "../CreateBookingNewPage/createBookingTableComponent/CreateBookingTableComponent";
import CreateBookingTableBookingSliderComponent, {
  CreateBookingTableBookingSliderComponentPayments,
} from "../CreateBookingNewPage/createBookingTableComponent/CreateBookingTableBookingSliderComponent";
import ModifyReservationPopupArrived from "../ModifyReservationPopup/ModifyReservationPopupArrived";
import BookingPayments from "../CreateBookingNewPage/bookingPaymentComponent/BookingPayments";
import ShareButton from "../../NewCustomComp/shareOnWhatsAppEmail/ShareButton";

const BookingDetailSliderNew = ({
  data,
  setShowBookingDetails,
  chargesDetails,
  masterFolioDetails,
  getSingleBookingDataHandler,
}) => {
  const navigate = useNavigate();
  const [openPrintPopup, setOpenPrintPopup] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [openMoreOptionsPopup, setOpenMoreOptionsPopup] = useState(false);
  const [openCheckedInMoreOptionsPopup, setOpenCheckedInMoreOptionsPopup] =
    useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  function handlePrintPopup() {
    setOpenPrintPopup(!openPrintPopup);
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  // if excape is pressed then close the popup

  const keyPressed = (event) => {
    if (event.key === "Escape") {
      setShowBookingDetails(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPressed);
    return () => {
      document.removeEventListener("keydown", keyPressed);
    };
  }, []);

  function handleEmailPopup() {
    setOpenEmailPopup(!openEmailPopup);
  }
  function handleMoreOptionsPopup() {
    setOpenMoreOptionsPopup(!openMoreOptionsPopup);
  }
  function handleCheckedInMoreOptionsPopup() {
    setOpenCheckedInMoreOptionsPopup(!openCheckedInMoreOptionsPopup);
  }
  const popupsRef = useRef(null);
  const bookingDetailsPopupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setOpenPrintPopup(false);
        setOpenEmailPopup(false);
        setOpenMoreOptionsPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const currentTimeToBackend = useCurrentTime("24");

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (
  //       bookingDetailsPopupRef.current &&
  //       !bookingDetailsPopupRef.current.contains(event.target)
  //     ) {
  //       setShowBookingDetails(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  const formattedDate = new Date(data.created_at).toLocaleString("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  });
  // ............Cancel Booking.............
  const [loader, setLoader] = useState(true);
  const [cancelReason, setCancelReason] = useState("");
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [bookings, setBookings] = useState([]);
  const [bookingOuterDetails, setBookingOuterDetails] = useState([]);
  const [cancelBookingId, setCancelBookingId] = useState("");
  const [dayTypeFilter, setDayTypeFilter] = useState("-1");
  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);
  const property_id = useSelector((state) => state.property.value);

  const fetchInfo = async (PropertyId, from_date, to_date) => {
    setLoader(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${PropertyId}`,
      {
        method: "POST",
        body: JSON.stringify({
          booking_status: selectedBookingStatus,
          date_filters: selectedDateFilter,
          booking_id: bookingId,
          dayTypeFilter: dayTypeFilter,
          from_date: from_date,
          to_date: to_date,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.status) {
          setBookings(data.Bookings);
          setBookingOuterDetails(data);
          showSuccessToast(data.message);
          setLoader(false);
          // navigate("/bookings");
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(
        property_id.id,
        selectedDatesRange.from_date,
        selectedDatesRange.to_date,
        selectedDateFilter,
        selectedBookingStatus,
        dayTypeFilter
      );
    }
  }, [property_id, selectedDateFilter, selectedBookingStatus, dayTypeFilter]);

  const cancelBookingHandle = (bookingId) => {
    // e.preventDefault();
    // const credentials = { email: email, password: password };
    // http://127.0.0.1:8000/api/v1/get/booking/9a0783fb-213b-4f31-ad9d-a5605d8672a4
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/cancelled/${bookingId}/${property_id?.id}?cancelReason=${cancelReason}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setLoader(false);
          fetchInfo(property_id.id);
          setShowModelMenuActive(false);
          window.location.reload();
        } else {
          // setSingleBookingDetail(data.booking);
          // setShowBookingDetails(true);
          // showSuccessToast("Data Fetched Successfully");
          showSuccessToast(data.message);
          setShowModelMenuActive(false);
          // setOpenSingleBookingDetails(!openSingleBookingDetails);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
    setShowBookingDetails(false);
  };

  function handleCancelBooking() {
    setShowModelMenuActive(true);
    setCancelBookingId(data?.id);
  }

  // ...................Remove Reservation..........
  const [reasonFromChild, setReasonFromChild] = useState("");
  const [uniqueBookingId, setUniqueBookingId] = useState(false);
  const [deleteWithReasonPopup, setDeleteWithReasonPopup] = useState(false);
  const [deleteKotId, setDeleteKotId] = useState("");
  function handleRemoveReservation() {
    setDeleteWithReasonPopup(true);
  }
  const handleDeleteWithReason = (reason) => {
    setReasonFromChild(reason);
  };

  // .........No show.........
  const [noShowData, setNoShowData] = useState(false);

  const handleNoShowData = (status, bookingId) => {
    setNoShowData(true);
    if (noShowData) {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/booking_status/${bookingId}/${status}/${property_id?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            showSuccessToast(data.message);
            window.location.reload();
          } else {
            showErrorToast(data.message);
          }
        });
    } else {
      setNoShowData(true);
    }
    // setLoader(true);
  };

  // ...............Assign Room....................
  const [clickedBookingId, setClickedBookingId] = useState("");
  const [shortBookingId, setShortBookingId] = useState("");
  const [showAssignRoomPopup, setShowAssignRoomPopup] = useState(false);
  const [assignRoomsData, setAssignRoomsData] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoooms, selectedRooooms] = useState({});
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const handleRoomClick = (roomId, maxRooms) => {
    setSelectedRoom((prev) => (prev === roomId ? "" : roomId));
  };
  const handleCheckboxChange = (roomId, roomName, roomNoId, room_no) => {
    selectedRooooms((prevSelectedRooms) => {
      const newSelectedRooms = { ...prevSelectedRooms };
      const currentSelection = newSelectedRooms[roomId] || [];

      if (currentSelection.includes(room_no)) {
        newSelectedRooms[roomId] = currentSelection.filter(
          (id) => id !== room_no
        );
      } else {
        const roomCategory = assignRoomsData.find((room) => room.id === roomId);
        const maxRooms = parseInt(roomCategory.no_of_rooms, 10);

        if (currentSelection.length < maxRooms) {
          newSelectedRooms[roomId] = [...currentSelection, room_no];
        } else {
          showErrorToast(
            `Cannot select more than ${maxRooms} rooms for ${roomName}`
          );
          return prevSelectedRooms;
        }
      }
      return newSelectedRooms;
    });
  };

  const assignRoomsHandle = (e, bookingId) => {
    // every
    // some

    const checkMaxRooms =
      selectedData &&
      selectedData.every((room) => {
        return (
          room.maxRooms ===
          (room.selectedRoooms ? room.selectedRoooms.length : 0)
        );
      });

    if (!checkMaxRooms) {
      showErrorToast("Please assign the rooms first.");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/room/no/${data?.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          assign_room: selectedData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setShowBookingDetails(false);
          // setBookingDetails(data.booking);
          setShowInHouseCheckIn(false);
          setShowAssignRoomPopup(false);
          showSuccessToast(data.message);
          // setShowDashBoardCheckIn(false);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const fetchAssignRooms = async (bookingId, propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/check-in/getdata/${bookingId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setAssignRoomsData(data.availablerooms);
          setLoader(false);
          setShowAssignRoomPopup(true);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      setLoader(false);
      showErrorToast("Something went wrong...");
      console.error(error);
    }
  };
  useEffect(() => {
    const newData = assignRoomsData.map((room) => ({
      name: room.room_name,
      id: room.id,
      selectedRoooms: selectedRoooms[room.id] || [],
      maxRooms: parseInt(room.no_of_rooms, 10),
    }));
    setSelectedData(newData);
  }, [selectedRoooms, assignRoomsData]);

  // void reservation
  const handleCancelStatusButton = async () => {
    // console.log(data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/booking/${data?.id}/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify({
            type: reasonFromChild.reason,
            duplicate_booking_id: uniqueBookingId,
            original_booking_id: reasonFromChild.originalBookingId,
            remarks: reasonFromChild.otherReason,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (res.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        setDeleteWithReasonPopup(false);
        window.location.reload();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
    // setShowBookingDetails(false);
  };

  // Voucher

  const [iframeContent, setIframeContent] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);

  const handleSubmitPreviewVoucher = async (
    event,
    template,
    statuses,
    vouchertitle,
    booking_id
  ) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomVoucher&reservationId=${booking_id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          setIframeContent(data);
          setShowIframePopup(true);
          // setShowBillPreview(true);
          // setIsLoading(false);
          // printIframe("receipt");
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentLists();
    }
  }, [property_id]);

  const [agentLists, setAgentLists] = useState([]);

  const fetchAgentLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAgentLists(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const agentListOptions = [
    {
      label: "Select option",
      value: "",
    },
    ...agentLists?.map((agent) => ({
      label: agent?.company_name,
      value: agent?.agent_information,
    })),
  ];

  // add payments

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "5%", value: "5" },
    { label: "12%", value: "12" },
    { label: "18%", value: "18" },
  ];
  const paymentTypeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash", value: "cash" },
    { label: "City Ledger", value: "city_ledger" },
  ];

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    // { label: "Other", value: "other" },
  ];

  const currentDate = new Date().toISOString().split("T")[0];

  const [addChargesData, setAddChargesData] = useState({
    date: currentDate,
    booking_id: "",
    folio_id: "",
    property_id: "",
    notes: "",
    notes: "",
    payment_mode: "",
    payment_type: "",
    amount: "",
  });

  const [roomDetails, setRoomDetails] = useState([]);

  const [agentCreditLimit, setAgentCreditLimit] = useState("");

  useEffect(() => {
    const selectedAgent = agentLists?.find(
      (agent) =>
        Number(agent?.agent_information) ===
        Number(addChargesData?.company_name)
    );

    if (selectedAgent && selectedAgent?.credit.length > 0) {
      const creditLimit = selectedAgent?.credit?.reduce(
        (acc, agent) => Number(acc) + Number(agent.balance_amount),
        0
      );
      setAgentCreditLimit(creditLimit);
    } else {
      setAgentCreditLimit(null);
    }

    setAddChargesData({
      ...addChargesData,
      amount: null,
    });
  }, [addChargesData.company_name, agentLists]);

  useEffect(() => {
    // Convert input values to numbers
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    // Apply GST
    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);
    }

    let totalAmount = priceAfterDiscount + gstAmount;

    setAddChargesData({
      ...addChargesData,
      // subtotal: subtotal.toFixed(2),
      // net_total: totalAmount.toFixed(2),
      // discount_amount: discountAmount.toFixed(2),
      // tax_amount: gstAmount.toFixed(2),
    });
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  const setAddChargesHandleChange = (event) => {
    if (event.target.name === "amount") {
      if (
        agentCreditLimit > 0 &&
        parseFloat(event.target.value) > agentCreditLimit
      ) {
        showErrorToast(
          "Amount should not be greater than Agent Balance Credit Limit"
        );
        return;
      }
    }
    if (event.target.name === "company_name") {
      setAddChargesData({
        // ...addChargesData,
        amount: 0,
      });
    }

    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  const [addPaymentPopup, setAddPaymentPopup] = useState(false);

  const settlementHandleFolio = (event) => {
    event.preventDefault();
    setButtonLoader(true);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/payments/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: data.folioId,
          property_id: property_id?.id,
          booking_id: data.id,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // fetchFolioDetails(property_id?.id);
          setButtonLoader(false);
          showSuccessToast(res.message);
          setAddChargesData({});
          setAddPaymentPopup(false);
          getSingleBookingDataHandler(data.unique_booking_id);
          fetchAgentLists();
        } else {
          showErrorToast(res.message);
          setButtonLoader(false);
        }
      })
      .catch((error) => {
        setButtonLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // Add charges

  useEffect(() => {
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;

      let totalAmount = priceAfterDiscount + gstAmount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);

      // GST Inclusive Price * 100/(100 + GST Rate Percentage)
      subtotal = (priceAfterDiscount * 100) / (100 + tax_rate);

      let totalAmount = priceAfterDiscount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    }
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);
  const [addChargesPopup, setAddChargesPopup] = useState(false);

  const serviceOptions = [
    { label: "Select Option", value: "" },
    { label: "Room-Charge", value: "Room-Charge" },
    { label: "Food-Charge", value: "Food-Charge" },
    { label: "Housekeeping-Charge ", value: "Housekeeping-Charge" },
    { label: "Miscellaneous-Charge", value: "Miscellaneous-Charge" },
    { label: "Laundry-Charge", value: "Laundry-Charge" },
    // { label: "MISC", value: "misc" },
  ];

  const handleAddCharges = (event) => {
    event.preventDefault();
    // console.log({ ...addChargesData, folio_id: folioData.id });
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/charges/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: masterFolioDetails.id,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          showSuccessToast(res.message);
          setAddChargesPopup(false);
          // fetchFolioInfo(property_id?.id);
          setAddChargesData({
            charge_type: "",
            description: "",
            rate: "",
            quantity: "",
            net_total: "",
            subtotal: "",
            discount_amount: "",
            gst_type: "inclusive",
            discount_rate: "",
            tax_rate: "",
            tax_amount: "",
          });

          // checkFolioSummery(
          //   userToken,
          //   folioData?.id,
          //   property_id?.id,
          //   chargeAddedTransactions,
          //   true
          // );
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // print
  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const handlePrintVoucher = async (
    event,
    template,
    statuses,
    vouchertitle,
    booking_id
  ) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomVoucher&reservationId=${booking_id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((res) => {
        if (res) {
          setIframeContent(res);
          // setShowIframePopup(true);
          // setShowBillPreview(true);
          // setIsLoading(false);
          setTimeout(() => {
            printIframe("receipt");
          }, 500);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handlePrintBill = async (
    event,
    template,
    statuses,
    vouchertitle,
    booking_id
  ) => {
    if (!data?.bill_generated) {
      // localStorage.removeItem("selectedFolioValue", ""),
      //   localStorage.removeItem("selectedFolioLabel", ""),
      showErrorToast("Bill not generated yet!");
      setTimeout(() => {
        window.open(
          `/bookings/folio-bills/${data?.id}/${data?.folioId}`,
          "_blank",
          data
        );
      }, 1000);

      return;
    }

    fetch(
      // https://dev.revcatalyst.in/api/v1/layout/preview/4/bill?previewType=roomBillPreview&previewId=301
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${booking_id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((res) => {
        if (res) {
          setIframeContent(res);
          // setShowIframePopup(true);
          // setShowBillPreview(true);
          // setIsLoading(false);
          setTimeout(() => {
            printIframe("receipt");
          }, 500);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [showModifyReservation, setShowModifyReservation] = useState(false);

  const handleModifyReservation = () => {
    setShowModifyReservation(true);
  };

  //============   addons in inclusion list ===========
  const [addOnsFromParentApi, setAddOnsFromParentApi] = useState([]);
  const [paymentsFromParentApi, setPaymentsFromParentApi] = useState([]);

  useEffect(() => {
    setAddOnsFromParentApi(data.addons);
  }, [data, setAddOnsFromParentApi]);
  useEffect(() => {
    setPaymentsFromParentApi(data.settlements);
  }, [data, setPaymentsFromParentApi]);

  const handleHoldBookingConfirmClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/confirm/hold/booking/${data.id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to confirm booking hold");
      }

      const responseData = await response.json();
      if (responseData.success) {
        showSuccessToast(responseData.message);
        window.location.reload();
      } else {
        showErrorToast(responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [showModifyArrivalsPopup, setShowModifyArrivalsPopup] = useState(false);

  return (
    <div className="bg-backdrop">
      <div
        id="booking_details_slider_container_new"
        ref={bookingDetailsPopupRef}
      >
        <div class="popup" id="popup">
          <div className="d_flex booking_detail_popup_head">
            <div
              onClick={() => setShowBookingDetails(false)}
              className="close_popup"
            >
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 12.5L13 0.5" stroke="#AAAAAA" stroke-width="1.2" />
                <path
                  d="M13 12.5L0.999999 0.500001"
                  stroke="#AAAAAA"
                  stroke-width="1.2"
                />
              </svg>
            </div>
            <div className="d_flex booking_id">
              {masterFolioDetails && masterFolioDetails?.folio_no && (
                <h3>
                  Folio No.{" "}
                  <strong>{"#" + masterFolioDetails?.folio_no}</strong>
                </h3>
              )}
              {data && data?.channel_booking_id && (
                <h3>
                  Channel Booking ID <strong>{"#" + data?.channel_booking_id}</strong>
                </h3>
              )}
 
              {data && data?.unique_booking_id && (
                <h3>
                  Booking ID <strong>{"#" + data?.unique_booking_id}</strong>
                </h3>
              )}
            </div>
          </div>
         

          {data.status === "confirmed" || data.status === "no_show" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
                <div className="print_btn">
                  <button
                    className="booking_details_options_btns items_center"
                    onClick={handlePrintPopup}
                    style={{ position: "relative" }}
                  >
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Print
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openPrintPopup && (
                      <div className="print_popup" ref={popupsRef}>
                        <p
                          onClick={(e) =>
                            handlePrintVoucher(
                              e,
                              "voucher",
                              "preview",
                              "Booking Voucher",
                              data?.unique_booking_id
                            )
                          }
                        >
                          Vouchers
                        </p>
                        {/* <p>GRC</p>
                        <p>Bill</p> */}
                      </div>
                    )}
                  </button>
                </div>

                <ShareButton data={data} propertyName={property_id?.property_name}/>
              </div>

              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div
                className="booking_details d_flex common_padding"
                style={{ paddingBottom: "0px" }}
              >
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Rooms</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.no_of_rooms),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>No of Child</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_child),
                          0
                        )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data?.check_in || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>Booking Source</h3>
                    <p>
                      {data && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "fit-content",
                          }}
                        >
                          {"Sales and Marketing" === data?.booking_source &&
                          data?.booking_source !== "channel_booking" ? (
                            <UnderscoreRemoveCapitalize
                              text={"Sales & Mark"}
                              width="fit-content"
                            />
                          ) : (
                            <UnderscoreRemoveCapitalize
                              text={data?.booking_source}
                              width="fit-content"
                            />
                          )}

                          {data?.booking_source !== "Walk In Booking" &&
                            data?.booking_source !== "channel_booking" &&
                            data?.booking_source !== "Front Desk" && (
                              <>
                                -
                                <UnderscoreRemoveCapitalize
                                  text={data?.booking_source_name}
                                />
                              </>
                            )}
                          {data?.booking_source === "channel_booking" && (
                            <>
                              <br />
                              {data?.channel_booking_id}
                            </>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) ||
                        "N/A"}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data?.check_out}</p>
                  </div>
                  {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
                  <div>
                    <h3>Extra Adult</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.extra_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "#3A68ED" }}>
                      {<UnderscoreRemoveCapitalize text={data?.status} />}
                    </p>
                  </div>
                </div>
              </div>

              {data?.remarks && (
                <div
                  className=" flex flex_gap_5 booking_details_col "
                  style={{ paddingTop: "0" }}
                >
                  {data?.remarks && (
                    <div
                      className="common_padding flex flex_gap_5 booking_details_col "
                      style={{ paddingTop: "0" }}
                    >
                      <h3>Remarks:</h3>{" "}
                      <p style={{ color: "rgba(102, 102, 102, 1)" }}>
                        {data?.remarks || "N/A"}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="boking_details_popup_overview ">
                <h3>Room & Plan</h3>
              </div>

              <div className="common_padding">
                <div>
                  {data.items.length < 1 ? (
                    <>No items</>
                  ) : (
                    <>
                      {data?.items?.map((item, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p className="w-33">
                            {item?.no_of_rooms}x {item?.room_type_name}
                          </p>
                          <p className="w-33 text-center">{item?.room_plan}</p>
                          <p className="w-33 text-center">
                            <p className="w-33 text-center">
                              {formatIndianCurrency(Number(item?.room_rate))}
                              /night
                            </p>
                          </p>
                          <p className="w-33 text-end">
                            {item?.combinedRooms ? (
                              <span>
                                {item.combinedRooms.length > 12 ? (
                                  <Tooltip content={item.combinedRooms}>
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "200px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.combinedRooms}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  item.combinedRooms
                                )}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </p>
                          {/* <div>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <g clip-path="url(#clip0_4924_7597)">
                                              <path
                                                  d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                  fill="#888888"
                                              />
                                              <path
                                                  d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                  fill="#888888"
                                              />
                                          </g>
                                          <defs>
                                              <clipPath id="clip0_4924_7597">
                                                  <rect width="11" height="11" fill="white" />
                                              </clipPath>
                                          </defs>
                                      </svg>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                              fill="#888888"
                                          />
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                              fill="#888888"
                                          />
                                      </svg>
                                  </div> */}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <div style={{margin:"20px 0"}}>
                <CreateBookingTableBookingSliderComponent
                  heading="Inclusion List"
                  bookingID={data.id}
                  TableHeaders={[
                    "Name",
                    "Qty",
                    "Amt",
                    "Dis. Amt",
                    "Tax Amt",
                    "Total Amt.",
                  ]}
                  addOnsFromParentApi={addOnsFromParentApi}
                  fetchBookingDetailsForAddons={() =>
                    getSingleBookingDataHandler(data.unique_booking_id)
                  }
                  grandTotal={data.grand_total}
                  totalTaxAmount={data.total_tax_amount}
                  totalDiscountAmount={data.total_discount_amount}
                  subtotal={data.taxable_amount}
                  paidAmount={data.paid_amount}
                  balanceAmount={data.balance_amount}
                />
              </div>
              <div>
                {/* <CreateBookingTableBookingSliderComponentPayments
                  heading="Payments"
                  bookingID={data.id}
                  TableHeaders={["Type", "Date", "Notes", "Total Amt."]}
                  addOnsFromParentApi={paymentsFromParentApi}
                  fetchBookingDetailsForAddons={() =>
                    getSingleBookingDataHandler(data.unique_booking_id)
                  }
                  grandTotal={data.grand_total}
                  totalTaxAmount={data.total_tax_amount}
                  totalDiscountAmount={data.total_discount_amount}
                  subtotal={data.taxable_amount}
                  paidAmount={data.paid_amount}
                  array={data.grand_total}
                  balanceAmount={data.balance_amount}
                /> */}
                <div>
                <BookingPayments
                 heading="Payments"
                 TableHeaders={["Type", "Date", "Notes", "Total Amt."]}
                 getPaymentData={()=>console.log("getPaymentData")}
                 bookingData={data}
                 bookingID={data.id}
                 addOnsFromParentApi={paymentsFromParentApi}
                 fetchBookingDetailsForAddons={() =>
                  getSingleBookingDataHandler(data.unique_booking_id)
                }
                headerStyle={{backgroundColor:"#f5f5f5" ,padding:"10px 20px"}}
                contentStyle={{padding:"0px 20px"}}
                
                
                
                
                />
                </div>

               
              </div>
              <div className="boking_details_popup_overview " style={{marginTop:"10px"}}>
                <h3>Price Breakup </h3>
              </div>

              <div className="booking_details price_breakup d_flex">
              <div className="booking_details_col" >
                  <div className="total_amt">
                    <h3 style={{color:"red"}}>Subtotal</h3>
                    <p style={{color:"red"}}> {data && data?.taxable_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3 style={{color:"#333"}}>Tax Amt.</h3>
                    <p style={{color:"#333"}}> {data && data?.total_tax_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data?.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data?.balance_amount}</p>
                  </div>
                </div>
              </div>
              <div className="d_flex common_padding booking_details_options booking_details_options_bottom_container">
                {/* <BgThemeButton
                  children={"Modify Reservation"}
                  style={{ height: "35px" }}
                /> */}

                {data.status === "confirmed" && (
                  <BgThemeButton
                    children={"Modify Reservation"}
                    onClick={handleModifyReservation}
                  />
                )}

                <BgOutlineThemeButton
                  style={{ height: "35px" }}
                  className="more_options_btn"
                  onClick={handleMoreOptionsPopup}
                  icon={
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                  }
                  children={"More Options"}
                >
                  More Options{" "}
                  {openMoreOptionsPopup && (
                    <div
                      className="more_options_popup"
                      ref={popupsRef}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <button onClick={() => setAddPaymentPopup(true)}>
                        Add Payment
                      </button>
                      <button
                        onClick={(e) =>
                          handleSubmitPreviewVoucher(
                            e,
                            "voucher",
                            "preview",
                            "Booking Voucher",
                            data?.unique_booking_id
                          )
                        }
                      >
                        View Voucher
                      </button>
                      <button onClick={handleRemoveReservation}>
                        Void Reservation
                      </button>
                      <button
                        onClick={() => handleNoShowData("no_show", data?.id)}
                      >
                        Move To No-Show
                      </button>
                      {/* <p>No Show</p>
                        <p>Add Add-on</p> */}
                    </div>
                  )}
                </BgOutlineThemeButton>

                <SettleBillButton onClick={handleCancelBooking}>
                  Cancel Booking
                </SettleBillButton>
              </div>

              {noShowData && (
                <div className="global_popup_container_background">
                  <div
                    className="global_popup_container"
                    style={{ width: "300px" }}
                  >
                    <p
                      style={{
                        fontWeight: "500",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      Are you sure you want to mark this booking as a no-show?
                    </p>
                    <div className="flex flex_gap_10 justify_content_center ">
                      <BgOutlineThemeButton
                        children={"Cancel"}
                        style={{ width: "100px", height: "35px" }}
                        onClick={() => setNoShowData(false)}
                      />
                      <BgThemeButton
                        children={"Confirm"}
                        style={{
                          backgroundColor: "red",
                          height: "35px",
                          width: "100px",
                        }}
                        onClick={() => handleNoShowData("no_show", data?.id)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {showModifyReservation && (
                <div className="global_popup_container_background">
                  <ModifyReservationPopup
                    setShowModifyPopup={setShowModifyReservation} // state passed from parent to close popup
                    bookingID={data?.id} // booking id
                    getBookingData={getSingleBookingDataHandler} // fxn call api pass unique booking id
                    bookingData={data}
                  />
                </div>
              )}
            </div>
          ) : data.status === "checked_in" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
                {data?.assigned_rooms ? (
                  <div>
                    <button
                      className="booking_popup_btns view_folio_btn"
                      onClick={() => [
                        navigate(`/bookings/folio/${data.id}/${data.folioId}`),
                        localStorage.removeItem("selectedFolioValue", ""),
                        localStorage.removeItem("selectedFolioLabel", ""),
                      ]}
                    >
                      View Folio
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="booking_popup_btns view_folio_btn"
                      onClick={() => [
                        setClickedBookingId(data?.unique_booking_id),
                        setShortBookingId(data?.id),
                        fetchAssignRooms(data?.id, property_id?.id),
                      ]}
                    >
                      Assign Room
                    </button>
                  </div>
                )}

                <div className="print_btn">
                  <button
                    className="booking_details_options_btns items_center"
                    onClick={handlePrintPopup}
                    style={{ position: "relative" }}
                  >
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Print
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openPrintPopup && (
                      <div className="print_popup" ref={popupsRef}>
                        <p
                          onClick={(e) =>
                            handlePrintVoucher(
                              e,
                              "voucher",
                              "preview",
                              "Booking Voucher",
                              data?.unique_booking_id
                            )
                          }
                        >
                          Vouchers
                        </p>
                        {/* <p>GRC</p>
                        <p>Bill</p> */}
                      </div>
                    )}
                  </button>
                </div>
                <ShareButton data={data} propertyName={property_id?.property_name}/>
                {/* <div className="email_btn">
                  <button
                    className="booking_details_options_btns items_center "
                    onClick={handleEmailPopup}
                  >
                    <svg
                      width="19"
                      height="13"
                      viewBox="0 0 19 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.1584V11.6071C0 12.3764 0.623603 13 1.39286 13H17.1786C17.9478 13 18.5714 12.3764 18.5714 11.6071V2.15831L9.75352 7.30209C9.4644 7.47075 9.10688 7.47075 8.81776 7.30209L0 2.1584Z"
                        fill="#3968ED"
                      />
                      <path
                        d="M18.0545 0.309833C17.8152 0.116071 17.5104 0 17.1786 0H1.39286C1.06095 0 0.756158 0.116092 0.516872 0.309885L9.28564 5.425L18.0545 0.309833Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Email{" "}
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openEmailPopup && (
                      <div className="email_popup" ref={popupsRef}>
                        <p>Vouchers</p>
                        <p>GRC</p>
                        <p>Bill</p>
                      </div>
                    )}
                  </button>
                </div> */}
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div
                className="booking_details d_flex common_padding"
                style={{ paddingBottom: "0px" }}
              >
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Rooms</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.no_of_rooms),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>No of Child</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_child),
                          0
                        )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data?.check_in || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>Booking Source</h3>
                    <p>
                      {data && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "fit-content",
                          }}
                        >
                          {"Sales and Marketing" === data?.booking_source &&
                          data?.booking_source !== "channel_booking" ? (
                            <UnderscoreRemoveCapitalize
                              text={"Sales & Mark"}
                              width="fit-content"
                            />
                          ) : (
                            <UnderscoreRemoveCapitalize
                              text={data?.booking_source}
                              width="fit-content"
                            />
                          )}

                          {data?.booking_source !== "Walk In Booking" &&
                            data?.booking_source !== "channel_booking" &&
                            data?.booking_source !== "Front Desk" && (
                              <>
                                -
                                <UnderscoreRemoveCapitalize
                                  text={data?.booking_source_name}
                                />
                              </>
                            )}
                          {data?.booking_source === "channel_booking" && (
                            <>
                              <br />
                              {data?.channel_booking_id}
                            </>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) ||
                        "N/A"}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data?.check_out}</p>
                  </div>
                  {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
                  <div>
                    <h3>Extra Adult</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.extra_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "green" }}>Arrived</p>
                  </div>
                </div>
              </div>

              {data?.remarks && (
                <div
                  className=" flex flex_gap_5 booking_details_col "
                  style={{ paddingTop: "0" }}
                >
                  {data?.remarks && (
                    <div
                      className="common_padding flex flex_gap_5 booking_details_col "
                      style={{ paddingTop: "0" }}
                    >
                      <h3>Remarks:</h3>{" "}
                      <p style={{ color: "rgba(102, 102, 102, 1)" }}>
                        {data?.remarks || "N/A"}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="boking_details_popup_overview ">
                <h3>Room & Plan</h3>
              </div>

              <div className="common_padding">
                <div>
                  {data.items.length < 1 ? (
                    <>No items</>
                  ) : (
                    <>
                      {data?.items?.map((item, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p className="w-33">
                            {item?.no_of_rooms}x {item?.room_type_name}
                          </p>
                          <p className="w-33 text-center">{item?.room_plan}</p>
                          <p className="w-33 text-center">
                            <p className="w-33 text-center">
                              {formatIndianCurrency(Number(item?.room_rate))}
                              /night
                            </p>
                          </p>
                          <p className="w-33 text-end">
                            {item?.combinedRooms ? (
                              <span>
                                {item.combinedRooms.length > 12 ? (
                                  <Tooltip content={item.combinedRooms}>
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "200px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.combinedRooms}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  item.combinedRooms
                                )}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </p>
                          {/* <div>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_4924_7597)">
                                            <path
                                                d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                fill="#888888"
                                            />
                                            <path
                                                d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                fill="#888888"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4924_7597">
                                                <rect width="11" height="11" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                            fill="#888888"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                            fill="#888888"
                                        />
                                    </svg>
                                </div> */}
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
                </div>
              </div>

              <div>
                <CreateBookingTableBookingSliderComponent
                  heading="Inclusion List"
                  bookingID={data.id}
                  TableHeaders={[
                    "Name",
                    "Amt",
                    "Dis. %",
                    "Tax Rate",
                    "Total Amt.",
                  ]}
                  addOnsFromParentApi={addOnsFromParentApi}
                  fetchBookingDetailsForAddons={() =>
                    getSingleBookingDataHandler(data.unique_booking_id)
                  }
                  grandTotal={data.grand_total}
                  totalTaxAmount={data.total_tax_amount}
                  totalDiscountAmount={data.total_discount_amount}
                  subtotal={data.taxable_amount}
                  paidAmount={data.paid_amount}
                />

                {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
              </div>

              <div className="boking_details_popup_overview ">
                <h3>Charge Breakup </h3>
              </div>
              <div className="booking_details d_flex common_padding">
                <div className="booking_details_col">
                  {data && (
                    <div>
                      <h3>Room</h3>
                      <p>
                        {(chargesDetails &&
                          chargesDetails["Room-Charge"] &&
                          chargesDetails["Room-Charge"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}

                  {chargesDetails && chargesDetails["housekeeping"] && (
                    <div>
                      <h3>Housekeeping</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["housekeeping"] &&
                          formatIndianCurrency(
                            chargesDetails["housekeeping"]?.net_total
                          )) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
                <div className="booking_details_col">
                  {chargesDetails && chargesDetails["f_and_b"] && (
                    <div>
                      <h3>Food & Beverages</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["f_and_b"] &&
                          formatIndianCurrency(
                            chargesDetails["f_and_b"]?.net_total
                          )) ||
                          0}
                      </p>
                    </div>
                  )}

                  {chargesDetails && chargesDetails["misc"] && (
                    <div>
                      <h3>Misc.</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["misc"] &&
                          formatIndianCurrency(
                            chargesDetails["misc"]?.net_total
                          )) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
                <div className="booking_details_col">
                  {chargesDetails && chargesDetails["room_service"] && (
                    <div>
                      <h3>Room Services</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["room_service"] &&
                          formatIndianCurrency(
                            formatIndianCurrency(
                              chargesDetails["room_Service"]?.net_total
                            )
                          )) ||
                          0}
                      </p>
                    </div>
                  )}
                  {chargesDetails && chargesDetails["Add-On"] && (
                    <div>
                      <h3>Add Ons</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["Add-On"] &&
                          chargesDetails["Add-On"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="booking_details price_breakup d_flex">
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data?.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data?.balance_amount}</p>
                  </div>
                </div>
              </div>
              <div className="d_flex common_padding booking_details_options booking_details_options_bottom_container">
                <BgThemeButton
                  children={"Modify Reservation"}
                  style={{ height: "35px" }}
                  onClick={() => setShowModifyArrivalsPopup(true)}
                />
                <BgOutlineThemeButton
                  style={{ height: "35px" }}
                  className="more_options_btn"
                  onClick={handleMoreOptionsPopup}
                  icon={
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                  }
                  children={"More Options"}
                >
                  More Options{" "}
                  {/* Add Payment
Add Charges
View Folio
View Voucher
Add Inclusions */}
                  {openMoreOptionsPopup && (
                    <div
                      className="more_options_popup"
                      ref={popupsRef}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <button onClick={() => setAddPaymentPopup(true)}>
                        Add Payment
                      </button>
                      <button
                        onClick={() => [
                          navigate(
                            `/bookings/folio/${data.id}/${data.folioId}`
                          ),
                          localStorage.removeItem("selectedFolioValue", ""),
                          localStorage.removeItem("selectedFolioLabel", ""),
                        ]}
                      >
                        View Folio
                      </button>
                      <button onClick={() => setAddChargesPopup(true)}>
                        Add Charges
                      </button>
                      <button
                        onClick={(e) =>
                          handleSubmitPreviewVoucher(
                            e,
                            "voucher",
                            "preview",
                            "Booking Voucher",
                            data?.unique_booking_id
                          )
                        }
                      >
                        View Voucher
                      </button>
                    </div>
                  )}
                </BgOutlineThemeButton>
              </div>

              {showModifyArrivalsPopup && (
                <div className="global_popup_container_background">
                  <ModifyReservationPopupArrived
                    setShowModifyPopup={setShowModifyArrivalsPopup} // state passed from parent to close popup
                    bookingID={data?.id} // booking id
                    getBookingData={getSingleBookingDataHandler} // fxn call api pass unique booking id
                    bookingData={data}
                  />
                </div>
              )}
            </div>
          ) : data.status === "hold" || data.status === "pending" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
              <ShareButton data={data} propertyName={property_id?.property_name}/>
                {/* <div>
                                    <button className="booking_popup_btns view_folio_btn">
                                        Assign Room
                                    </button>
                                </div> */}
                {/* <div className="print_btn">
                  <button
                    className="booking_details_options_btns items_center"
                    onClick={handlePrintPopup}
                    style={{ position: "relative" }}
                  >
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Print
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openPrintPopup && (
                      <div className="print_popup" ref={popupsRef}>
                        <p
                          onClick={(e) =>
                            handlePrintVoucher(
                              e,
                              "voucher",
                              "preview",
                              "Booking Voucher",
                              data?.unique_booking_id
                            )
                          }
                        >
                          Vouchers
                        </p>
                        <p>GRC</p>
                        <p>Bill</p>
                      </div>
                    )}
                  </button>
                </div>
                <div className="email_btn">
                  <button
                    className="booking_details_options_btns items_center "
                    onClick={handleEmailPopup}
                  >
                    <svg
                      width="19"
                      height="13"
                      viewBox="0 0 19 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.1584V11.6071C0 12.3764 0.623603 13 1.39286 13H17.1786C17.9478 13 18.5714 12.3764 18.5714 11.6071V2.15831L9.75352 7.30209C9.4644 7.47075 9.10688 7.47075 8.81776 7.30209L0 2.1584Z"
                        fill="#3968ED"
                      />
                      <path
                        d="M18.0545 0.309833C17.8152 0.116071 17.5104 0 17.1786 0H1.39286C1.06095 0 0.756158 0.116092 0.516872 0.309885L9.28564 5.425L18.0545 0.309833Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Email{" "}
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openEmailPopup && (
                      <div className="email_popup" ref={popupsRef}>
                        <p>Vouchers</p>
                        <p>GRC</p>
                        <p>Bill</p>
                      </div>
                    )}
                  </button>
                </div> */}
              </div>

              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div
                className="booking_details d_flex common_padding"
                style={{ paddingBottom: "0px" }}
              >
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Rooms</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.no_of_rooms),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>No of Child</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_child),
                          0
                        )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data?.check_in || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>Booking Source</h3>
                    <p>
                      {data && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "fit-content",
                          }}
                        >
                          {"Sales and Marketing" === data?.booking_source &&
                          data?.booking_source !== "channel_booking" ? (
                            <UnderscoreRemoveCapitalize
                              text={"Sales & Mark"}
                              width="fit-content"
                            />
                          ) : (
                            <UnderscoreRemoveCapitalize
                              text={data?.booking_source}
                              width="fit-content"
                            />
                          )}

                          {data?.booking_source !== "Walk In Booking" &&
                            data?.booking_source !== "channel_booking" &&
                            data?.booking_source !== "Front Desk" && (
                              <>
                                -
                                <UnderscoreRemoveCapitalize
                                  text={data?.booking_source_name}
                                />
                              </>
                            )}
                          {data?.booking_source === "channel_booking" && (
                            <>
                              <br />
                              {data?.channel_booking_id}
                            </>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) ||
                        "N/A"}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data?.check_out}</p>
                  </div>
                  {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
                  <div>
                    <h3>Extra Adult</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.extra_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "#BD941B" }}>
                      {<UnderscoreRemoveCapitalize text={data?.status} />}
                    </p>
                  </div>
                </div>
              </div>

              {data?.remarks && (
                <div
                  className=" flex flex_gap_5 booking_details_col "
                  style={{ paddingTop: "0" }}
                >
                  {data?.remarks && (
                    <div
                      className="common_padding flex flex_gap_5 booking_details_col "
                      style={{ paddingTop: "0" }}
                    >
                      <h3>Remarks:</h3>{" "}
                      <p style={{ color: "rgba(102, 102, 102, 1)" }}>
                        {data?.remarks || "N/A"}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div>
                <CreateBookingTableBookingSliderComponent
                  heading="Inclusion List"
                  bookingID={data.id}
                  TableHeaders={[
                    "Name",
                    "Amt",
                    "Dis. %",
                    "Tax Rate",
                    "Total Amt.",
                  ]}
                  addOnsFromParentApi={addOnsFromParentApi}
                  fetchBookingDetailsForAddons={() =>
                    getSingleBookingDataHandler(data.unique_booking_id)
                  }
                  grandTotal={data.grand_total}
                  totalTaxAmount={data.total_tax_amount}
                  totalDiscountAmount={data.total_discount_amount}
                  subtotal={data.taxable_amount}
                  paidAmount={data.paid_amount}
                />

                {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Price Breakup </h3>
              </div>
              {/* <div className="booking_details d_flex common_padding">
                                <div className="booking_details_col">

                                    {data &&
                                        <div>
                                            <h3>Room</h3>
                                            <p> {chargesDetails && chargesDetails["Room Charge"] && formatIndianCurrency(chargesDetails["Room Charge"]?.net_total) || 0}</p>
                                        </div>}


                                    {chargesDetails && chargesDetails["housekeeping"] &&
                                        <div>
                                            <h3>Houekeeping</h3>
                                            <p> {chargesDetails && chargesDetails["housekeeping"] && formatIndianCurrency(chargesDetails["housekeeping"]?.net_total) || 0}</p>
                                        </div>}

                                </div>
                                <div className="booking_details_col">
                                    {chargesDetails && chargesDetails["f_and_b"] &&
                                        <div>
                                            <h3>Food & Beverages</h3>
                                            <p> {chargesDetails && chargesDetails["f_and_b"] && formatIndianCurrency(chargesDetails["f_and_b"]?.net_total) || 0}</p>
                                        </div>}

                                    {chargesDetails && chargesDetails["misc"] &&
                                        <div>
                                            <h3>Misc.</h3>
                                            <p> {chargesDetails && chargesDetails["misc"] && formatIndianCurrency(chargesDetails["misc"]?.net_total) || 0}</p>
                                        </div>}
                                </div>
                                <div className="booking_details_col">
                                    {chargesDetails && chargesDetails["room_service"] &&
                                        <div>
                                            <h3>Room Services</h3>
                                            <p> {chargesDetails && chargesDetails["room_service"] && formatIndianCurrency(formatIndianCurrency(chargesDetails["room_Service"]?.net_total)) || 0}</p>
                                        </div>}
                                    {chargesDetails && chargesDetails["add_ons"] &&
                                        <div>
                                            <h3>Add Ons</h3>
                                            <p> {chargesDetails && chargesDetails["add_ons"] && chargesDetails["add_ons"]?.net_total || 0}</p>
                                        </div>}
                                </div>
                            </div> */}
              <div className="booking_details price_breakup d_flex">
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data.balance_amount}</p>
                  </div>
                </div>
              </div>
              <div className="d_flex common_padding booking_details_options booking_details_options_bottom booking_details_options_bottom_container">
                {/* <button className="booking_popup_btns view_folio_btn ">
                                Modify Reservation
                            </button> */}
                <div className="d_flex" style={{ gap: "20px" }}>
                  <BgThemeButton
                    children={"Confirm Booking"}
                    className="booking_detail_bottom_btn"
                    onClick={handleHoldBookingConfirmClick}
                  />

                  <SettleBillButton
                    style={{ height: "35px" }}
                    onClick={handleCancelBooking}
                  >
                    Cancel Booking
                  </SettleBillButton>
                </div>

                <div >
                  <p>Hold Till</p>
                  {data?.hold_date && data?.hold_time && (
                    <span>
                     fwefer {
                        <TextDateFormatter
                          date={data?.hold_date}
                          showYear={true}
                          as={"span"}
                        />
                      }{" "}
                      - {data?.hold_time}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ) : data.status === "checked_out" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
                {/* <div>
                  <button className="booking_popup_btns view_folio_btn">
                    Assign Room
                  </button>
                </div> */}
                <div className="print_btn">
                  <button
                    className="booking_details_options_btns items_center"
                    onClick={handlePrintPopup}
                    style={{ position: "relative" }}
                  >
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Print
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openPrintPopup && (
                      <div className="print_popup" ref={popupsRef}>
                        <p
                          onClick={(e) =>
                            handlePrintVoucher(
                              e,
                              "voucher",
                              "preview",
                              "Booking Voucher",
                              data?.unique_booking_id
                            )
                          }
                        >
                          Vouchers
                        </p>
                        {/* <p>GRC</p> */}
                        <p
                          onClick={(e) =>
                            handlePrintBill(
                              e,
                              "voucher",
                              "preview",
                              "Booking Voucher",
                              data?.folioId
                            )
                          }
                        >
                          Bill
                        </p>
                      </div>
                    )}
                  </button>
                </div>
                {/* <div className="email_btn">
                  <button
                    className="booking_details_options_btns items_center "
                    onClick={handleEmailPopup}
                  >
                    <svg
                      width="19"
                      height="13"
                      viewBox="0 0 19 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.1584V11.6071C0 12.3764 0.623603 13 1.39286 13H17.1786C17.9478 13 18.5714 12.3764 18.5714 11.6071V2.15831L9.75352 7.30209C9.4644 7.47075 9.10688 7.47075 8.81776 7.30209L0 2.1584Z"
                        fill="#3968ED"
                      />
                      <path
                        d="M18.0545 0.309833C17.8152 0.116071 17.5104 0 17.1786 0H1.39286C1.06095 0 0.756158 0.116092 0.516872 0.309885L9.28564 5.425L18.0545 0.309833Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Email{" "}
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openEmailPopup && (
                      <div className="email_popup" ref={popupsRef}>
                        <p>Vouchers</p>
                        <p>GRC</p>
                        <p>Bill</p>
                      </div>
                    )}
                  </button>
                </div> */}
                 <ShareButton data={data} propertyName={property_id?.property_name}/>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div
                className="booking_details d_flex common_padding"
                style={{ paddingBottom: "0px" }}
              >
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Rooms</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.no_of_rooms),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>No of Child</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_child),
                          0
                        )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data?.check_in || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>Booking Source</h3>
                    <p>
                      {data && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "fit-content",
                          }}
                        >
                          {"Sales and Marketing" === data?.booking_source &&
                          data?.booking_source !== "channel_booking" ? (
                            <UnderscoreRemoveCapitalize
                              text={"Sales & Mark"}
                              width="fit-content"
                            />
                          ) : (
                            <UnderscoreRemoveCapitalize
                              text={data?.booking_source}
                              width="fit-content"
                            />
                          )}

                          {data?.booking_source !== "Walk In Booking" &&
                            data?.booking_source !== "channel_booking" &&
                            data?.booking_source !== "Front Desk" && (
                              <>
                                -
                                <UnderscoreRemoveCapitalize
                                  text={data?.booking_source_name}
                                />
                              </>
                            )}
                          {data?.booking_source === "channel_booking" && (
                            <>
                              <br />
                              {data?.channel_booking_id}
                            </>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) ||
                        "N/A"}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data?.check_out}</p>
                  </div>
                  {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
                  <div>
                    <h3>Extra Adult</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.extra_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "#3A68ED" }}>
                      {<UnderscoreRemoveCapitalize text={data?.status} />}
                    </p>
                  </div>
                </div>
              </div>
              {data?.remarks && (
                <div
                  className=" flex flex_gap_5 booking_details_col "
                  style={{ paddingTop: "0" }}
                >
                  {data?.remarks && (
                    <div
                      className="common_padding flex flex_gap_5 booking_details_col "
                      style={{ paddingTop: "0" }}
                    >
                      <h3>Remarks:</h3>{" "}
                      <p style={{ color: "rgba(102, 102, 102, 1)" }}>
                        {data?.remarks || "N/A"}
                      </p>
                    </div>
                  )}
                </div>
              )}
              <div className="boking_details_popup_overview ">
                <h3>Room & Plan</h3>
              </div>

              <div className="common_padding">
                <div>
                  {data.items.length < 1 ? (
                    <>No items</>
                  ) : (
                    <>
                      {data?.items?.map((item, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p className="w-33">
                            {item?.no_of_rooms}x {item?.room_type_name}
                          </p>
                          <p className="w-33 text-center">{item?.room_plan}</p>
                          <p className="w-33 text-center">
                            <p className="w-33 text-center">
                              {formatIndianCurrency(Number(item?.room_rate))}
                              /night
                            </p>
                          </p>
                          <p className="w-33 text-end">
                            {item?.combinedRooms ? (
                              <span>
                                {item.combinedRooms.length > 12 ? (
                                  <Tooltip content={item.combinedRooms}>
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "200px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.combinedRooms}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  item.combinedRooms
                                )}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </p>
                          {/* <div>
                                     <svg
                                         width="11"
                                         height="11"
                                         viewBox="0 0 11 11"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                         <g clip-path="url(#clip0_4924_7597)">
                                             <path
                                                 d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                 fill="#888888"
                                             />
                                             <path
                                                 d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                 fill="#888888"
                                             />
                                         </g>
                                         <defs>
                                             <clipPath id="clip0_4924_7597">
                                                 <rect width="11" height="11" fill="white" />
                                             </clipPath>
                                         </defs>
                                     </svg>
                                     <svg
                                         width="11"
                                         height="11"
                                         viewBox="0 0 11 11"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                         <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                             fill="#888888"
                                         />
                                         <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                             fill="#888888"
                                         />
                                     </svg>
                                 </div> */}
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
                </div>
              </div>

              <div>
                <CreateBookingTableBookingSliderComponent
                  heading="Inclusion List"
                  bookingID={data.id}
                  TableHeaders={[
                    "Name",
                    "Amt",
                    "Dis. %",
                    "Tax Rate",
                    "Total Amt.",
                  ]}
                  addOnsFromParentApi={addOnsFromParentApi}
                  fetchBookingDetailsForAddons={() =>
                    getSingleBookingDataHandler(data.unique_booking_id)
                  }
                  grandTotal={data.grand_total}
                  totalTaxAmount={data.total_tax_amount}
                  totalDiscountAmount={data.total_discount_amount}
                  subtotal={data.taxable_amount}
                  paidAmount={data.paid_amount}
                />

                {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Charge Breakup </h3>
              </div>
              <div className="booking_details d_flex common_padding">
                <div className="booking_details_col">
                  {data && (
                    <div>
                      <h3>Room</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["Room Charge"] &&
                          formatIndianCurrency(
                            chargesDetails["Room Charge"]?.net_total
                          )) ||
                          0}
                      </p>
                    </div>
                  )}

                  {chargesDetails && chargesDetails["housekeeping"] && (
                    <div>
                      <h3>Housekeeping</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["housekeeping"] &&
                          formatIndianCurrency(
                            chargesDetails["housekeeping"]?.net_total
                          )) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
                <div className="booking_details_col">
                  {chargesDetails && chargesDetails["f_and_b"] && (
                    <div>
                      <h3>Food & Beverages</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["f_and_b"] &&
                          formatIndianCurrency(
                            chargesDetails["f_and_b"]?.net_total
                          )) ||
                          0}
                      </p>
                    </div>
                  )}

                  {chargesDetails && chargesDetails["misc"] && (
                    <div>
                      <h3>Misc.</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["misc"] &&
                          formatIndianCurrency(
                            chargesDetails["misc"]?.net_total
                          )) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
                <div className="booking_details_col">
                  {chargesDetails && chargesDetails["room_Service"] && (
                    <div>
                      <h3>Room Services</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["room_Service"] &&
                          formatIndianCurrency(
                            chargesDetails["room_Service"]?.net_total
                          )) ||
                          0}
                      </p>
                    </div>
                  )}
                  {chargesDetails && chargesDetails["add_ons"] && (
                    <div>
                      <h3>Add Ons</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["add_ons"] &&
                          chargesDetails["add_ons"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="booking_details price_breakup d_flex">
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data?.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data?.balance_amount}</p>
                  </div>
                </div>
              </div>
              <div className="d_flex common_padding booking_details_options booking_details_options_bottom_container">
                <BgOutlineThemeButton
                  style={{ height: "35px" }}
                  className="more_options_btn"
                  onClick={handleMoreOptionsPopup}
                  icon={
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                  }
                  children={"More Options"}
                >
                  More Options{" "}
                  {openMoreOptionsPopup && (
                    <div
                      className="more_options_popup"
                      ref={popupsRef}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <button
                        onClick={() => [
                          navigate(
                            `/bookings/folio/${data.id}/${data.folioId}`
                          ),
                          localStorage.removeItem("selectedFolioValue", ""),
                          localStorage.removeItem("selectedFolioLabel", ""),
                        ]}
                      >
                        View Folio
                      </button>
                      <button
                        onClick={(e) =>
                          handleSubmitPreviewVoucher(
                            e,
                            "voucher",
                            "preview",
                            "Booking Voucher",
                            data?.unique_booking_id
                          )
                        }
                      >
                        View Voucher
                      </button>
                    </div>
                  )}
                </BgOutlineThemeButton>
              </div>
            </div>
          ) : data.status === "cancelled" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
                {/* <div>
                                    <button className="booking_popup_btns view_folio_btn">
                                        Assign Room
                                    </button>
                                </div> */}
                <div className="print_btn">
                  <button
                    className="booking_details_options_btns items_center"
                    onClick={handlePrintPopup}
                    style={{ position: "relative" }}
                  >
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Print
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openPrintPopup && (
                      <div className="print_popup" ref={popupsRef}>
                        <p
                          onClick={(e) =>
                            handlePrintVoucher(
                              e,
                              "voucher",
                              "preview",
                              "Booking Voucher",
                              data?.unique_booking_id
                            )
                          }
                        >
                          Vouchers
                        </p>
                        {/* <p>GRC</p>
                        <p>Bill</p> */}
                      </div>
                    )}
                  </button>
                </div>
                {/* <div className="email_btn">
                  <button
                    className="booking_details_options_btns items_center "
                    onClick={handleEmailPopup}
                  >
                    <svg
                      width="19"
                      height="13"
                      viewBox="0 0 19 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.1584V11.6071C0 12.3764 0.623603 13 1.39286 13H17.1786C17.9478 13 18.5714 12.3764 18.5714 11.6071V2.15831L9.75352 7.30209C9.4644 7.47075 9.10688 7.47075 8.81776 7.30209L0 2.1584Z"
                        fill="#3968ED"
                      />
                      <path
                        d="M18.0545 0.309833C17.8152 0.116071 17.5104 0 17.1786 0H1.39286C1.06095 0 0.756158 0.116092 0.516872 0.309885L9.28564 5.425L18.0545 0.309833Z"
                        fill="#3968ED"
                      />
                    </svg>
                    Email{" "}
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                    {openEmailPopup && (
                      <div className="email_popup" ref={popupsRef}>
                        <p>Vouchers</p>
                        <p>GRC</p>
                        <p>Bill</p>
                      </div>
                    )}
                  </button>
                </div> */}
                 <ShareButton data={data} propertyName={property_id?.property_name}/>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div
                className="booking_details d_flex common_padding"
                style={{ paddingBottom: "0px" }}
              >
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Rooms</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.no_of_rooms),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>No of Child</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_child),
                          0
                        )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data?.check_in || <span>N/A</span>}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div style={{ paddingBottom: "0px" }}>
                    <h3>Booking Source</h3>
                    <p>
                      {data && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "fit-content",
                          }}
                        >
                          {"Sales and Marketing" === data?.booking_source &&
                          data?.booking_source !== "channel_booking" ? (
                            <UnderscoreRemoveCapitalize
                              text={"Sales & Mark"}
                              width="fit-content"
                            />
                          ) : (
                            <UnderscoreRemoveCapitalize
                              text={data?.booking_source}
                              width="fit-content"
                            />
                          )}

                          {data?.booking_source !== "Walk In Booking" &&
                            data?.booking_source !== "channel_booking" &&
                            data?.booking_source !== "Front Desk" && (
                              <>
                                -
                                <UnderscoreRemoveCapitalize
                                  text={data?.booking_source_name}
                                />
                              </>
                            )}
                          {data?.booking_source === "channel_booking" && (
                            <>
                              <br />
                              {data?.channel_booking_id}
                            </>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) ||
                        "N/A"}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data?.check_out}</p>
                  </div>
                  {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
                  <div>
                    <h3>Extra Adult</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) =>
                            Number(acc) + Number(item?.extra_adult),
                          0
                        )}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "#FF0000" }}>
                      {<UnderscoreRemoveCapitalize text={data?.status} />}
                    </p>
                  </div>
                </div>
              </div>

              {data?.remarks && (
                <div
                  className="common_padding flex flex_gap_5 booking_details_col "
                  style={{ paddingTop: "0" }}
                >
                  <h3>Remarks:</h3> <p>{data?.remarks || "N/A"}</p>
                </div>
              )}
              {data?.cancelReason && (
                <div
                  className="common_padding flex flex_gap_5 booking_details_col "
                  style={{ paddingTop: "0" }}
                >
                  <h3 style={{ color: "#FF0000" }}>Cancel Reason:</h3>{" "}
                  <p>{data?.cancelReason || "N/A"}</p>
                </div>
              )}

              <div className="boking_details_popup_overview ">
                <h3>Room & Plan</h3>
              </div>

              <div className="common_padding">
                <div>
                  {data.items.length < 1 ? (
                    <>No items</>
                  ) : (
                    <>
                      {data?.items?.map((item, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p className="w-33">
                            {item?.no_of_rooms}x {item?.room_type_name}
                          </p>
                          <p className="w-33 text-center">{item?.room_plan}</p>
                          <p className="w-33 text-center">
                            <p className="w-33 text-center">
                              {formatIndianCurrency(Number(item?.room_rate))}
                              /night
                            </p>
                          </p>
                          <p className="w-33 text-end">
                            {item?.combinedRooms ? (
                              <span>
                                {item.combinedRooms.length > 12 ? (
                                  <Tooltip content={item.combinedRooms}>
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "200px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.combinedRooms}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  item.combinedRooms
                                )}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </p>
                          {/* <div>
                                     <svg
                                         width="11"
                                         height="11"
                                         viewBox="0 0 11 11"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                         <g clip-path="url(#clip0_4924_7597)">
                                             <path
                                                 d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                 fill="#888888"
                                             />
                                             <path
                                                 d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                 fill="#888888"
                                             />
                                         </g>
                                         <defs>
                                             <clipPath id="clip0_4924_7597">
                                                 <rect width="11" height="11" fill="white" />
                                             </clipPath>
                                         </defs>
                                     </svg>
                                     <svg
                                         width="11"
                                         height="11"
                                         viewBox="0 0 11 11"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                         <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                             fill="#888888"
                                         />
                                         <path
                                             fill-rule="evenodd"
                                             clip-rule="evenodd"
                                             d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                             fill="#888888"
                                         />
                                     </svg>
                                 </div> */}
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
                </div>
              </div>

              <div className="boking_details_popup_overview ">
                <h3>Inclusion List</h3>
              </div>
              <div className="common_padding">
                <div>
                  {data.addons.length < 1 ? (
                    <>No addons added.</>
                  ) : (
                    <>
                      {data?.addons?.map((item, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p className="w-33">
                            {item?.no_of_rooms}x {item?.room_type_name}
                          </p>
                          <p className="w-33 text-center">{item?.room_plan}</p>
                          <p className="w-33 text-center">
                            <p className="w-33 text-center">
                              {formatIndianCurrency(Number(item?.room_rate))}
                              /night
                            </p>
                          </p>
                          <p className="w-33 text-end">
                            {item?.combinedRooms ? (
                              <span>
                                {item.combinedRooms.length > 12 ? (
                                  <Tooltip content={item.combinedRooms}>
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "200px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.combinedRooms}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  item.combinedRooms
                                )}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </p>
                          {/* <div>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <g clip-path="url(#clip0_4924_7597)">
                                              <path
                                                  d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                  fill="#888888"
                                              />
                                              <path
                                                  d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                  fill="#888888"
                                              />
                                          </g>
                                          <defs>
                                              <clipPath id="clip0_4924_7597">
                                                  <rect width="11" height="11" fill="white" />
                                              </clipPath>
                                          </defs>
                                      </svg>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                              fill="#888888"
                                          />
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                              fill="#888888"
                                          />
                                      </svg>
                                  </div> */}
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div>
                                        <svg
                                            width="11"
                                            height="11"
                                            viewBox="0 0 11 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clip-path="url(#clip0_4924_7597)">
                                                <path
                                                    d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                    fill="#888888"
                                                />
                                                <path
                                                    d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                    fill="#888888"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4924_7597">
                                                    <rect width="11" height="11" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <svg
                                            width="11"
                                            height="11"
                                            viewBox="0 0 11 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                                fill="#888888"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                                fill="#888888"
                                            />
                                        </svg>
                                    </div> */}
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Charge Breakup </h3>
              </div>
              {/* <div className="booking_details d_flex common_padding">
                                <div className="booking_details_col">

                                    {data &&
                                        <div>
                                            <h3>Room</h3>
                                            <p> {chargesDetails && chargesDetails["Room Charge"] && formatIndianCurrency(chargesDetails["Room Charge"]?.net_total) || 0}</p>
                                        </div>}


                                    {chargesDetails && chargesDetails["housekeeping"] &&
                                        <div>
                                            <h3>Houekeeping</h3>
                                            <p> {chargesDetails && chargesDetails["housekeeping"] && formatIndianCurrency(chargesDetails["housekeeping"]?.net_total) || 0}</p>
                                        </div>}

                                </div>
                                <div className="booking_details_col">
                                    {chargesDetails && chargesDetails["f_and_b"] &&
                                        <div>
                                            <h3>Food & Beverages</h3>
                                            <p> {chargesDetails && chargesDetails["f_and_b"] && formatIndianCurrency(chargesDetails["f_and_b"]?.net_total) || 0}</p>
                                        </div>}

                                    {chargesDetails && chargesDetails["misc"] &&
                                        <div>
                                            <h3>Misc.</h3>
                                            <p> {chargesDetails && chargesDetails["misc"] && formatIndianCurrency(chargesDetails["misc"]?.net_total) || 0}</p>
                                        </div>}
                                </div>
                                <div className="booking_details_col">
                                    {chargesDetails && chargesDetails["room_service"] &&
                                        <div>
                                            <h3>Room Services</h3>
                                            <p> {chargesDetails && chargesDetails["room_service"] && formatIndianCurrency(formatIndianCurrency(chargesDetails["room_Service"]?.net_total)) || 0}</p>
                                        </div>}
                                    {chargesDetails && chargesDetails["add_ons"] &&
                                        <div>
                                            <h3>Add Ons</h3>
                                            <p> {chargesDetails && chargesDetails["add_ons"] && chargesDetails["add_ons"]?.net_total || 0}</p>
                                        </div>}
                                </div>
                            </div> */}
              <div className="booking_details price_breakup d_flex">
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data?.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data?.balance_amount}</p>
                  </div>
                </div>
              </div>
              {/* <div className="d_flex common_padding booking_details_options booking_details_options_bottom_container">
                             
                                <BgThemeButton children={'Modify Reservation'} style={{ height: '35px' }} />
                                <BgOutlineThemeButton style={{ height: '35px' }}
                                    onClick={handleMoreOptionsPopup}
                                    icon={
                                        <svg
                                            width="12"
                                            height="8"
                                            viewBox="0 0 12 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1 1L6 6L11 1"
                                                stroke="#3968ED"
                                                stroke-width="1.5"
                                            />
                                        </svg>}
                                    children={'More Options'}

                                />

                              {openMoreOptionsPopup && (
                                    <div className="more_options_popup" ref={popupsRef}>
                                        <p>Remove Reservation</p>
                                        <p>No Show</p>
                                        <p>Add Add-on</p>
                                    </div>
                                )}
                                <button className="booking_cancel_btn items_center">
                                    Cancel Booking{" "}
                                </button>
                            </div> */}
            </div>
          ) : (
            ""
          )}
         
        </div>
        {showModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                <p style={{ marginBottom: "0" }}>
                  Do You Want To Cancel Booking?
                </p>
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div className="reason-input-button">Enter Reason</div>
                    <div className="reason-input-button">
                      {/* {reason?.reason?.trim()?.length}/{wordLimit} */}
                    </div>
                  </div>
                  <div style={{ width: "100%", marginBottom: "10px" }}>
                    <VerticalTextAreaCustom
                      name={"cancelReason"}
                      className="textfield"
                      focused={false}
                      type="text"
                      value={cancelReason}
                      autoComplete="off"
                      onChange={(e) => setCancelReason(e.target.value)}
                      cols={9}
                    />
                  </div>
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                  >
                    Discard
                  </Button>

                  {cancelReason?.trim()?.length > 0 ? (
                    <Button
                      onClick={() => cancelBookingHandle(data?.id)}
                      destructive
                    >
                      Yes, Cancel
                    </Button>
                  ) : (
                    <Button
                      onClick={() => cancelBookingHandle(data?.id)}
                      disabled
                    >
                      Yes, Cancel
                    </Button>
                  )}
                  {/* <Button
                    onClick={() => cancelBookingHandle(data?.id)}
                    destructive
                    type={"Button"}
                  >
                    Yes, Cancel
                  </Button> */}
                </div>
              </section>
            </div>
          </>
        )}

        {openCheckedInMoreOptionsPopup && (
          <div className="more_options_popup" ref={popupsRef}>
            <button onClick={() => handleNoShowData("no_show", data?.id)}>
              Move To No Show
            </button>
            <p>Add Add-on</p>
          </div>
        )}
        {/* {openPrintPopup && (
                    <div className="print_popup" ref={popupsRef}>
                        <p>Vouchers</p>
                        <p>GRC</p>
                        <p>Bill</p>
                    </div>
                )} */}

        {deleteWithReasonPopup && (
          <DeletePopupWithReasonBooking
            title={"Booking"}
            textAreaName={"reason_of_cancelation"}
            onDeleteWithReason={handleDeleteWithReason}
            wordLimit={50}
            deleteWithReasonPopup={deleteWithReasonPopup}
            setDeleteWithReasonPopup={setDeleteWithReasonPopup}
            deleteFunction={(data) => handleCancelStatusButton(data)}
            id={deleteKotId}
            btnText={"Yes, Delete"}
            type={"Button"}
            cancelText={"You Want To Delete This Booking?"}
          />
        )}

        {showAssignRoomPopup && (
          <div className="bg-backdrop">
            <div
              style={{
                width: "500px",
                marginBottom: "20px",
                // height: "500px",
                // overflowY: "scroll",
              }}
              className="room-plan-add-model-container  overflow_x_scroll_y_hidden"
            >
              <GoBackButtonCustom
                buttonNextText={`Assign Rooms for Booking ID : ${clickedBookingId}`}
                onClick={() => [
                  setShowAssignRoomPopup(false),
                  setSelectedRoom(""),
                ]}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  flexDirection: "column",
                }}
              >
                {selectedData && selectedData.length > 0 ? (
                  selectedData.map((data, index) => (
                    <div
                      key={index}
                      className={`room-box ${
                        selectedRoom === data.name ? "selected" : ""
                      }`}
                    >
                      <div className="availableRoomsSelectBoxContainer">
                        <div
                          className="availableRoomsSelectBox"
                          onClick={() =>
                            handleRoomClick(data.name, data.maxRooms)
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>{data.name}</span>
                            <span>Rooms:({data.maxRooms})</span>
                            <span
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <svg
                                viewBox="0 0 20 20"
                                class="Polaris-Icon__Svg"
                                focusable="false"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                        {selectedRoom === data.name && (
                          <div className="checkbox-container">
                            {assignRoomsData
                              .find((room) => room.room_name === data.name)
                              .sorted_rooms.map((roomNo) => (
                                <div
                                  className="flex flex-gap-10"
                                  key={roomNo.id}
                                >
                                  <input
                                    style={{ marginRight: "5px" }}
                                    type="checkbox"
                                    checked={data.selectedRoooms.includes(
                                      roomNo.room_no
                                    )}
                                    id={`room-${roomNo.room_no}`}
                                    value={roomNo.room_no}
                                    disabled={
                                      roomNo.status === "occupied" ||
                                      roomNo.status === "dirty" ||
                                      roomNo.status === "out_of_order"
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        roomNo.room_id,
                                        data.name,
                                        roomNo.id,
                                        roomNo.room_no
                                      )
                                    }
                                  />
                                  <label htmlFor={`room-${roomNo.room_no}`}>
                                    {`Room ${
                                      roomNo.room_no
                                    } (${roomNo.status.replace(/_/g, " ")})`}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div>No Rooms </div>
                  </>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <BgThemeButton
                  onClick={(e) => assignRoomsHandle(e, data?.id)}
                  children={"Assign"}
                  type={"button"}
                />

                <BgOutlineThemeButton
                  onClick={() => [
                    setShowAssignRoomPopup(false),
                    setSelectedRoom(""),
                  ]}
                  children={"Discard"}
                />
              </div>
            </div>
          </div>
        )}

        {iframeContent && showIframePopup && (
          <BookingVoucher
            setShowIframePopup={setShowIframePopup}
            iframeContent={iframeContent}
          />
        )}

        {/* add payments */}
        {addPaymentPopup && (
          <div className="bg-backdrop">
            <form
              onSubmit={settlementHandleFolio}
              style={{ width: "600px", zIndex: "10" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Add Payments
                </div>
                <div
                  onClick={() => setAddPaymentPopup(!addPaymentPopup)}
                  style={{ cursor: "pointer" }}
                >
                  {/* <Icon source={CancelMajor} color="base" /> */}
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="date"
                    type="date"
                    titleName="Date"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.date || currentDate}
                    // max={data.check_out}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={paymentTypeOptions}
                    type="text"
                    required={true}
                    focused={false}
                    name="payment_type"
                    titleName="Payment Type"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.payment_type}
                  />
                </div>
                <div className="form-container-div">
                  {addChargesData.payment_type === "cash" && (
                    <VerticalInputSelectCustomCopy
                      options={paymentModeOptions}
                      type="text"
                      required={true}
                      focused={false}
                      name="payment_mode"
                      titleName="Payment Mode"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.payment_mode}
                    />
                  )}
                  {addChargesData.payment_type === "city_ledger" && (
                    <VerticalInputSelectCustomCopy
                      options={agentListOptions}
                      type="text"
                      required={true}
                      focused={false}
                      name="company_name"
                      titleName="Company Name"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.company_name}
                    />
                  )}
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="notes"
                    titleName="Notes"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.notes}
                  />
                </div>
                <div className="form-container-div">
                  {agentCreditLimit < 1 &&
                  addChargesData.payment_type === "city_ledger" ? (
                    <VerticalInputFieldCustom
                      type="number"
                      required={true}
                      focused={false}
                      disabled={true}
                      name="amount"
                      titleName="Amount"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.amount}
                    />
                  ) : (
                    <VerticalInputFieldCustom
                      type="number"
                      required={true}
                      focused={false}
                      // disabled={true}
                      name="amount"
                      titleName="Amount"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.amount}
                    />
                  )}
                </div>
              </div>
              {addChargesData.payment_type === "city_ledger" && (
                <div className="form-container-div-full">
                  <>
                    Balance Credit Limit:
                    <span>
                      {agentCreditLimit
                        ? formatIndianCurrency(agentCreditLimit)
                        : formatIndianCurrency(agentCreditLimit)}
                    </span>
                  </>
                </div>
              )}

              <div className="form-container-div-full">
                <>
                  Pending Balance:
                  <span>{formatIndianCurrency(data?.balance_amount)}</span>
                </>
              </div>

              <div
                className="flex flex_gap_10"
                style={{ marginLeft: "10px", zIndex: "100" }}
              >
                {Number(data?.balance_amount) >=
                Number(addChargesData.amount) ? (
                  //  <BgThemeButton children={"Add"} type={"submit"} />
                  <BgThemeButton
                    style={{ zIndex: "100" }}
                    children={"Add"}
                    type={"submit"}
                    loader={buttonLoader}
                  />
                ) : (
                  <BgThemeButtonDisabled children={"Add"} />
                )}

                <BgOutlineThemeButton
                  children={"Cancel"}
                  type={"button"}
                  onClick={() => [
                    setAddPaymentPopup(!addPaymentPopup),
                    setAddChargesData({
                      date: currentDate,
                      booking_id: "",
                      folio_id: "",
                      property_id: "",
                      notes: "",
                      notes: "",
                      payment_mode: "",
                      payment_type: "",
                      amount: "",
                    }),
                  ]}
                />
              </div>
            </form>
          </div>
        )}

        {/* add charges */}
        {addChargesPopup && (
          <div className="bg-backdrop">
            <form
              onSubmit={handleAddCharges}
              style={{ width: "1000px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Add Charges
                </div>
                <div
                  onClick={() => setAddChargesPopup(!addChargesPopup)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    options={serviceOptions}
                    titleName="Service"
                    name="charge_type"
                    value={addChargesData.charge_type}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Description"
                    name="description"
                    type="text"
                    value={addChargesData.description}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Rate"
                    name="rate"
                    type="number"
                    value={addChargesData.rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Qty"
                    name="quantity"
                    type="number"
                    value={addChargesData.quantity}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Discount%"
                    name="discount_rate"
                    type="number"
                    value={addChargesData.discount_rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>

                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    options={gstTypeOptions}
                    required={true}
                    titleName="GST Type"
                    name="gst_type"
                    value={addChargesData.gst_type}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    required={true}
                    options={gstOptions}
                    titleName="GST%"
                    name="tax_rate"
                    value={addChargesData.tax_rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Subtotal"
                    name="sub_total"
                    disabled={true}
                    type="number"
                    value={addChargesData.subtotal}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div>
                Subtotal :{" "}
                {addChargesData.gst_type === "inclusive" ? (
                  // <HandleNaN
                  //   value={addChargesData.subtotal - addChargesData.tax_amount}
                  // />
                  <HandleNaN value={addChargesData.subtotal} />
                ) : (
                  <HandleNaN value={addChargesData.subtotal} />
                )}
              </div>

              <div>
                Tax Amount : <HandleNaN value={addChargesData.tax_amount} />
              </div>
              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Total Amount"
                    name="net_total"
                    type="number"
                    disabled={true}
                    value={
                      addChargesData.gst_type === "inclusive"
                        ? // ? addChargesData.net_total - addChargesData.tax_amount
                          addChargesData.net_total
                        : addChargesData.net_total
                    }
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div className="flex flex_gap_10">
                <BgThemeButton children={"Add"} type={"submit"} />
                <BgOutlineThemeButton
                  children={"Cancel"}
                  type={"button"}
                  onClick={() => setAddChargesPopup(!addChargesPopup)}
                />
              </div>
            </form>
          </div>
        )}

        <iframe
          srcDoc={iframeContent}
          id="receipt"
          style={{ display: "none" }}
          title="Receipt"
        />
      </div>
    </div>
  );
};
export default BookingDetailSliderNew;
