import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useIndexResourceState } from "@shopify/polaris";
import { formatIndianCurrency, getBaseUrl, getCurrentPropertyDateHandler, globalErrorHandler } from "../../utils/utils";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import SearchComponent from "../CustomComponents/SearchComponent/SearchComponent";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import CustomSelectOptionsComponent from "../CustomComponents/CustomSelectOptionsComponent/CustomSelectOptionsComponent";
import { BgThemeButton, WithoutBgButtonBlue } from "../UI/Buttons";


const FlexiPaySetting = () => {
    const [issueItemsTableData, setIssueItemsTableData] = useState([]);
    const [units, setUnits] = useState([]);
    const baseUrl = getBaseUrl();
    const [propertyData, sestPropertyData] = useState({});
    const [categories, setCategories] = useState([]);
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    const property_id = useSelector((state) => state.property.value);
    const [loader, setLoader] = useState(false);
    const [orders, setOrders] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [purchasedItems, setPurchasedItems] = useState([]);
    const [errors, setErrors] = useState({});

    const [allOrders, setAllOrders] = useState([]);

    const [departments, setDepartments] = useState([]);
    const navigate = useNavigate();
    // Pagignation
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (newPage) => {
        const totalPages = Math.ceil(orders.length / itemsPerPage);
        setCurrentPage(newPage > totalPages ? totalPages : newPage);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentOrders = orders?.slice(startIndex, endIndex);

    const [propertyCurrentDate, setPropertyCurrentDate] = useState();

    useEffect(() => {
        if (property_id) {
            getCurrentPropertyDateHandler(property_id?.id, userToken)
                .then((date) => setPropertyCurrentDate(date))
                .catch((error) => console.error(error));
        }
    }, [property_id]);

    const [issueItems, setIssueItems] = useState({
        min_occupancy: "",
        max_occupancy: "",
        flexi_pay: "",
    });

    const [outerData, setOuterData] = useState({
        department_name: "",
        issue_to: "",
        issue_date: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedIssueItems = { ...issueItems, [name]: value };

        if (name === "quantity") {
            const max_qty = +issueItems.max_qty;
            const input_qty = +value;

            if (value === "0") {
                showErrorToast("Quantity cannot be 0");
                setErrors({
                    ...errors,
                    qty: "Quantity cannot be greater than max quantity",
                    quantity: "Quantity cannot be greater than max quantity",
                });
                setTimeout(() => {
                    setErrors({});
                }, 1500);
                return;
            }

            if (input_qty > max_qty) {
                setErrors({
                    ...errors,
                    quantity: "Quantity cannot be greater than max quantity",
                });
                setTimeout(() => {
                    setErrors({});
                }, 1500);
                showErrorToast("Quantity cannot be greater than max quantity");
                return;
            }

            const remain_qty = max_qty - input_qty;
            const price = +issueItems.item_price;
            const totalAmount = price * input_qty;

            updatedIssueItems.total_amount = totalAmount;
            updatedIssueItems.remain_qty = remain_qty;
        }

        if (name === "flexi_pay") {
            const flexi_pay_value = +value;
            if (flexi_pay_value > 100) {
                showErrorToast("Flexi Pay cannot be greater than 100%");
                return; // Stop the state update if value exceeds 100
            }
        }


        setIssueItems(updatedIssueItems);
    };

    const handleAddItem = () => {
        const errors = globalErrorHandler(issueItems, ["min_occupancy", "max_occupancy", "flexi_pay"]);

        setErrors(errors.errors);
        setTimeout(() => {
            setErrors({});
            return clearTimeout();
        }, 3000);

        if (parseInt(issueItems.max_occupancy) <= parseInt(issueItems.min_occupancy)) {
            return showErrorToast("Max Occupancy must be greater than Min Occupancy.");
        }

        if (issueItemsTableData.length > 0) {
            const lastItem = issueItemsTableData[issueItemsTableData.length - 1];
            if (parseInt(issueItems.min_occupancy) <= parseInt(lastItem.max_occupancy)) {
                return showErrorToast("Min Occupancy must be greater than the previous item's Max Occupancy.");
            }
        }

        if (errors.success) {
            return showErrorToast("Please fill out all required fields.");
        }

        // Combine the new item with existing flexi_pay_details, ensuring no duplicates
        const newItem = { ...issueItems };
        const updatedFlexiPayItems = [
            ...issueItemsTableData,
            newItem
        ];

        // Set the new flexi pay details
        setIssueItemsTableData(updatedFlexiPayItems);
        setIssueItems({ min_occupancy: '', max_occupancy: '', flexi_pay: '' });
    };

    // const handleDeleteItem = (index) => {
    //     const newData = [...issueItemsTableData];
    //     newData.splice(index, 1);
    //     setIssueItemsTableData(newData);
    // };

    const handleDeleteItem = (index) => {
        // Remove the item from issueItemsTableData by index
        const newIssueItems = [...issueItemsTableData];
        newIssueItems.splice(index, 1);
        setIssueItemsTableData(newIssueItems);
    };

    const handleUpdateItem = async (id) => {
        // const errors = globalErrorHandler(outerData, [
        //     "department_name",
        //     "issue_to",
        // ]);

        setErrors(errors.errors);
        setTimeout(() => {
            setErrors({});
            return clearTimeout();
        }, 3000);

        console.log(errors.errors);

        console.log({
            ...outerData,
            issue_date: propertyCurrentDate,
            details: issueItemsTableData,
        });
        if (errors.success)
            return showErrorToast("Please fill out all required fields.");

        try {
            setLoader(true);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/create/issues/${property_id?.id}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        ...outerData,
                        issue_date: propertyCurrentDate,
                        details: issueItemsTableData,
                        total_amount: totalAmt,
                        total_items_quantity: totalQty,
                        total_items: totalItems,
                        reason_of_issue: 'stock'
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data.success) {
                // fetchInfo(property_id?.id);
                // setShowEditModelMenuActive(false);
                // clearSelection();
                showSuccessToast(data.message);
                // setItemsData({
                //   item_name: "",
                //   unit_name: "",
                //   category_name: "",
                //   hsn_code: "",
                //   issue_to: "",
                //   tax_slab: "",
                // });
                navigate("/hotelier/purchase/issueitems");
                // window.location.reload()
            } else {
                showErrorToast(data.message);
            }
        } catch (error) {
            console.error("Error:", error);
            showErrorToast("An error occurred while processing your request.");
        }
    };

    const totalItems = issueItemsTableData.length;
    const totalQty = issueItemsTableData.reduce(
        (acc, item) => acc + parseInt(item.quantity),
        0
    );
    const totalAmt = issueItemsTableData.reduce(
        (acc, item) => acc + parseFloat(item.total_amount),
        0
    );

    const getPropertyData = async (id) => {
        try {
            const response = await fetch(
                `${baseUrl}/api/v1/properties/edit/${property_id?.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            sestPropertyData(data?.property);
            setIssueItemsTableData(Array.isArray(data?.property?.flexi_pay_details) ? data?.property?.flexi_pay_details : []);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (property_id) {
            getPropertyData(property_id?.id);
        }
    }, [property_id]);

    const handleUpdateFlexiPay = async () => {
        try {
            setLoader(true);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/properties/update/flexipay/${property_id?.id}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        is_flexi_pay_active: issueItemsTableData.length <= 0 ? 0 : 1,
                        flexi_pay_details: issueItemsTableData,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data.success) {
                showSuccessToast(data.message);
            } else {
                showErrorToast(data.message);
            }
        } catch (error) {
            console.error("Error:", error);
            showErrorToast("An error occurred while processing your request.");
        }
    };

    return (
        <div className="main_container">
            <div class="room-type-heading mb-10">Flexi Pay</div>
            <div
                style={{
                    background: "rgba(255, 255, 255, 1)",
                    padding: "20px",
                    borderRadius: "10px",
                }}
            >
                {propertyData.is_flexi_pay_active === 1 ? (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="flex flex_gap_10">
                                <div style={{ width: "150px" }}>
                                    <VerticalInputFieldCustom
                                        titleName={"Min Occupancy (%)"}
                                        required={true}
                                        value={issueItems.min_occupancy}
                                        onChange={(e) => handleChange(e)}
                                        name="min_occupancy"
                                        error={errors?.min_occupancy ? true : false}
                                        type={'number'}
                                    />
                                </div>
                                <div style={{ width: "150px" }}>
                                    <VerticalInputFieldCustom
                                        titleName={"Max Occupancy (%)"}
                                        required={true}
                                        value={issueItems.max_occupancy}
                                        onChange={(e) => handleChange(e)}
                                        name="max_occupancy"
                                        error={errors?.max_occupancy ? true : false}
                                        type={'number'}
                                    />
                                </div>
                                <div style={{ width: "150px" }}>
                                    <VerticalInputFieldCustom
                                        titleName={"Flexi Pay (%)"}
                                        required={true}
                                        value={issueItems.flexi_pay}
                                        onChange={(e) => handleChange(e)}
                                        name="flexi_pay"
                                        error={errors?.flexi_pay ? true : false}
                                        type={'number'}
                                    />
                                </div>

                                <WithoutBgButtonBlue
                                    style={{ fontSize: "14px", padding: "0" }}
                                    icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <g clipPath="url(#clip0_5311_4678)">
                                                <path d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z" stroke="#3968ED" />
                                                <path d="M10.5868 7H3.41357" stroke="#3968ED" stroke-miterlimit="10" />
                                                <path d="M7 3.4132L7 10.5864" stroke="#3968ED" stroke-miterlimit="10" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_5311_4678">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    }
                                    children={"Add"}
                                    onClick={handleAddItem}
                                />
                            </div>
                        </div>

                        {issueItemsTableData.length > 0 ? (
                            <div>
                                <table
                                    id="issue_items_page_table"
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                        fontFamily: "Inter",
                                        width: "100%",
                                        marginTop: "25px",
                                    }}
                                >
                                    <thead>
                                        <tr style={{ textAlign: "left", marginBottom: "10px" }}>
                                            <th style={{ padding: "0px" }}>Min Occupancy (%)</th>
                                            <th style={{ padding: "0px" }}>Max Occupancy (%)</th>
                                            <th style={{ padding: "0px" }}>Flexi Pay (%)</th>
                                            <th style={{ padding: "0px" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {issueItemsTableData?.map((item, index) => (
                                            <tr key={index + propertyData?.flexi_pay_details.length}>
                                                <td style={{ padding: "10px 0" }}>{item.min_occupancy}</td>
                                                <td style={{ padding: "10px 0" }}>{item.max_occupancy}</td>
                                                <td style={{ padding: "10px 0" }}>{item.flexi_pay}</td>
                                                <td style={{ padding: "10px", width: "10px" }}>
                                                    <div onClick={() => handleDeleteItem(index, false)} style={{ cursor: "pointer" }}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                                                fill="#E03838"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                                                fill="#E03838"
                                                            />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : null}
                        <BgThemeButton children={'Update'} onClick={handleUpdateFlexiPay} />
                    </div>
                )
                    :
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: "10px",
                            }}
                        >
                            <div className="flex flex_gap_10">
                                <div style={{ width: "150px" }}>
                                    <VerticalInputFieldCustom
                                        titleName={"Min Occupancy (%)"}
                                        required={true}
                                        value={issueItems.min_occupancy}
                                        onChange={(e) => handleChange(e)}
                                        name="min_occupancy"
                                        error={errors?.min_occupancy ? true : false}
                                        type={'number'}
                                    />
                                </div>
                                <div style={{ width: "150px" }}>
                                    <VerticalInputFieldCustom
                                        titleName={"Max Occupancy (%)"}
                                        required={true}
                                        value={issueItems.max_occupancy}
                                        onChange={(e) => handleChange(e)}
                                        name="max_occupancy"
                                        error={errors?.max_occupancy ? true : false}
                                        type={'number'}
                                    />
                                </div>
                                <div style={{ width: "150px" }}>
                                    <VerticalInputFieldCustom
                                        titleName={"Flexi Pay (%)"}
                                        required={true}
                                        value={issueItems.flexi_pay}
                                        onChange={(e) => handleChange(e)}
                                        name="flexi_pay"
                                        error={errors?.flexi_pay ? true : false}
                                        type={'number'}
                                    />
                                </div>

                                <WithoutBgButtonBlue
                                    style={{ fontSize: "14px", padding: "0" }}
                                    icon={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_5311_4678)">
                                                <path
                                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                    stroke="#3968ED"
                                                />
                                                <path
                                                    d="M10.5868 7H3.41357"
                                                    stroke="#3968ED"
                                                    stroke-miterlimit="10"
                                                />
                                                <path
                                                    d="M7 3.4132L7 10.5864"
                                                    stroke="#3968ED"
                                                    stroke-miterlimit="10"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_5311_4678">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    }
                                    children={"Add"}
                                    onClick={handleAddItem}
                                />
                            </div>
                        </div>
                        {issueItemsTableData.length > 0 && (
                            <>
                                <div>
                                    <table
                                        id="issue_items_page_table"
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "10px",
                                            fontFamily: "Inter",
                                            width: "100%",
                                            marginTop: "25px",
                                        }}
                                    >
                                        <tr
                                            style={{
                                                textAlign: "left",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <th style={{ padding: "0px" }}>Min Occupancy</th>
                                            <th style={{ padding: "0px" }}>Max Occupancy</th>
                                            <th style={{ padding: "0px" }}>Flexi Pay</th>
                                        </tr>

                                        {issueItemsTableData?.map((item, index) => (
                                            <tr key={index}>
                                                <>
                                                    <td style={{ padding: "10px 0" }}>{item.min_occupancy}</td>
                                                    <td style={{ padding: "10px 0" }}>{item.max_occupancy}</td>
                                                    <td style={{ padding: "10px 0" }}>{item.flexi_pay}</td>
                                                    <td
                                                        style={{
                                                            padding: "10px",
                                                            width: "10px",
                                                        }}
                                                    >
                                                        <div
                                                            onClick={() => handleDeleteItem(index)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                                                    fill="#E03838"
                                                                />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                                                    fill="#E03838"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </td>
                                                </>
                                            </tr>
                                        ))}
                                        <BgThemeButton children={'Update'} onClick={handleUpdateFlexiPay} />
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                }
            </div>
        </div>
    );
};
export default FlexiPaySetting;
