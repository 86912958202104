import React from "react";
import ReactQuill from "react-quill";
const ReactQuillTextEditor = ({ editorHtml, name, handleTextChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["align", "code-block"],
      ["link", "image"],
      ["undo", "redo"], // Add undo and redo options
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "code-block",
    "link",
    "image",
  ];
  const onChange = (value) => {
   if (name){
    handleTextChange(name, value);
   } else{
    handleTextChange(value);
   }

  };
  return (
    <div>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={editorHtml}
        onChange={onChange}
      />
    </div>
  );
};

export default ReactQuillTextEditor;
