import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BgThemeButton, BgThemeButtonDisabled } from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import { useSelector } from "react-redux";
import { Tooltip } from "@shopify/polaris";
import NoDataFound from "../Components/CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import SliderComponentSkeleton from "./NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";
import TextDateFormatter from "./Communication/TextDateFormatter/TextDateFormatter";
import InventorySliderComponent from "./CustomComponents/BookingSoldSlider/InventorySliderComponent";
import {
  SelectBasic,
  SelectMultiple,
} from "../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { getActiveApp, getBaseUrl } from "../utils/utils";
import styles from "./styles/inventory/inventoryCommon.module.scss";
import StayRestrictions from "./CustomComponents/InventorySliderComponents/StayRestrictions";
import InventoryUpdate from "./CustomComponents/InventorySliderComponents/InventoryUpdate";
import InventoryRatesUpdate from "./CustomComponents/InventorySliderComponents/InventoryRatesUpdate";
import InventoryUnblock from "./CustomComponents/InventorySliderComponents/InventoryUnblock";
import InventoryBulkBlock from "./CustomComponents/InventorySliderComponents/InventoryBulkBlock";
import InventoryUnblockChannelManager from "./CustomComponents/InventorySliderComponents/InventoryUnblockChannelManager";
import InventoryBulkBlockChannelManager from "./CustomComponents/InventorySliderComponents/InventoryBulkBlockChannelManager";
import ConfirmPopup from "./NewCustomComp/confirmPopup/ConfirmPopup";
import InventoryChannelMapping from "./ChannelManagerSettings/InventoryChannelMapping";
import InventoryDatePicker from "./CustomComponents/InventorySliderComponents/InventoryDatePicker";
import InventoryAddChannel from "./ChannelManagerSettings/InventoryAddChannel";
import { SvgLock, SvgUnLock, SvgCancel } from "../assets/svgIcons";
import InventoryStopSell from "./CustomComponents/InventorySliderComponents/InventoryStopSell";

const InventoryCommon = () => {
  const baseUrl = getBaseUrl();
  const [deleteWithReasonData, setDeleteWithReasonData] = useState("");

  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  //
  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const property_id =
    useSelector((state) => state.property.value) ||
    localStorage.getItem("selectedProperty");
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const [selectedValues, setSelectedValues] = useState([]);

  const [selectedDatePickerDate, setSelectedDatePickerDate] =
    useState(formattedDate);

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const monthMonth = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${monthMonth}-${day}`;
  };
  const navigate = useNavigate();

  const [showTotalBookings, setShowTotalBookings] = useState(false);

  useEffect(() => {
    if (showTotalBookings) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showTotalBookings]);

  const [totalBookingData, setShowTotalBookingsData] = useState(null);
  const [inventorySelectedRoomName, setInventorySelectedRoomName] =
    useState(null);
  const [inventorySelectedDate, setInventorySelectedDate] = useState(null);

  const fetchSoldRoomsData = async (
    roomId,
    date,
    propertyId,
    inventorySelectedRoomName,
    inventorySelectedDate
  ) => {
    setInventorySelectedDate(inventorySelectedDate);
    setInventorySelectedRoomName(inventorySelectedRoomName);

    try {
      const response = await fetch(
        // `${baseUrl}/api/v1/get/inventory/sold/hold/details/${roomId}/${date}/${propertyId}`
        `${baseUrl}/api/v1/get/inventory/sold/hold/details/${roomId}/${date}/${propertyId}`
      );

      const data = await response.json().then((data) => {
        if (data.success) {
          setShowTotalBookingsData(data);
          setShowTotalBookings(true);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ==========================new code=============================
  const activeURL = useLocation().pathname;
  // filter for
  const fetchRooms = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/rooms/${property_id.id}`);

      const data = await response.json().then((data) => {
        if (data.success) {
          setShowTotalBookingsData(data);
          setCategories(data.rooms);
          setSelectedRoom(data.rooms[0]);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [categories, setCategories] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});

  useEffect(() => {
    if (property_id) {
      fetchRooms();
    }
  }, [property_id]);

  const handleRoomCategoryChange = (data) => {
    // fetchInfo(property_id?.id, data ,"channel_view");
    setSelectedRoom(categories.filter((item) => item.id === data)[0]);
  };

  const fetchInfo = async (property_id, id, view_type) => {
    // 'view_type' => 'required|string|in:channel_view,room_view',
    const url =
      view_type === "channel_view"
        ? `${baseUrl}/api/v1/inventories/${property_id}?fetchType=inventory&room_id=${id}&view_type=${view_type}`
        : `${baseUrl}/api/v1/inventories/${property_id}?fetchType=inventory&channel_id=${id}&view_type=${view_type}`;
    try {
      setLoader(true);
      const response = await fetch(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();

      if (data.success) {
        setData(data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };
  const fetchInfoWithDate = async (id, date, view_type) => {
    const url =
      view_type === "channel_view"
        ? `${baseUrl}/api/v1/inventories/${property_id?.id}?date=${date}&fetchType=inventory&room_id=${id}`
        : `${baseUrl}/api/v1/inventories/${property_id?.id}?date=${date}&fetchType=inventory&channel_id=${id}`;
    try {
      setLoader(true);
      const response = await fetch(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const dateClickHandler = (date) => {
    const id =
      viewType === "channel_view"
        ? selectedRoom?.id
        : selectedChannel?.channel_id;
    fetchInfoWithDate(id, date, viewType);
  };
  const handleIncrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() + 1);
      setSelectedDatePickerDate(formatDate(newDate));
      const id =
        viewType === "channel_view"
          ? selectedRoom?.id
          : selectedChannel?.channel_id;
      fetchInfoWithDate(id, formatDate(newDate), viewType);
    }
  };

  const handleDecrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() - 1);
      setSelectedDatePickerDate(formatDate(newDate));
      const id =
        viewType === "channel_view"
          ? selectedRoom?.id
          : selectedChannel?.channel_id;
      fetchInfoWithDate(id, formatDate(newDate), viewType);
    }
  };

  useEffect(() => {
    if (property_id && categories.length > 0 && selectedRoom) {
      fetchInfo(property_id?.id, selectedRoom?.id, "channel_view");
    }
  }, [property_id, categories, selectedRoom?.id]);

  const handleDateChange = (date) => {
    setSelectedDatePickerDate(formatDate(date));
    const id =
      viewType === "channel_view"
        ? selectedRoom?.id
        : selectedChannel?.channel_id;
    fetchInfoWithDate(id, formatDate(date), viewType);
  };

  // inventory table block unblock
  const [hoveredId, setHoveredId] = useState(null);

  function getMonthDay(dateString) {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day (e.g., 23)
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase(); // Get the month (e.g., AUG)

    return `${day}<br/> ${month}`;
  }

  // end inventory table block unblock

  const activeApp = getActiveApp();

  const controlsBulk = [
    "Inventory",
    // "Rates",
    "Block",
    "Unblock",
    // "Drip Feed",
    "Stay Restrictions",
    "Stop Sell Active",
    "Stop Sell Inactive",
  ];

  // chanel listing aip
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});

  const fetchChannels = async () => {
    if (activeApp !== "channel_manager") {
      return;
    }
    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/mapped/channels/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setChannels(data.data);
        setSelectedChannel(data.data[0]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoader(false);
    }
  };

  const [showBookingSlider, setShowBookingSlider] = useState(false);
  const [sliderHeading, setSliderHeading] = useState("");

  useEffect(() => {
    if (property_id && property_id?.id) {
      fetchChannels();
    }
  }, [property_id]);

  const handleBulkControlChange = (data) => {
    switch (data) {
      case "Stay Restrictions": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Stop Sell Active": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Stop Sell Inactive": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }

      case "Inventory": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Rates": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Block": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Unblock": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }

      default:
        break;
    }
  };

  const [showConfirmPopupBlock, setShowConfirmPopupBlock] = useState(false);

  const [showConfirmPopupUnblock, setShowConfirmPopupUnblock] = useState(false);

  const handleConfirmBlockInventory = async () => {
    if (activeApp === "channel_manager") {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/update/inventory/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify({
              from_date: deleteWithReasonData.childData.date,
              to_date: deleteWithReasonData.childData.date,
              status: "Close",
              channels: [
                {
                  channel_id: deleteWithReasonData?.roomData.channel_id,
                  channel_name: deleteWithReasonData?.roomData.channel_name,
                },
              ],
              inventory_data: [
                {
                  room_id: deleteWithReasonData?.childData.room_id,
                  available_rooms:
                    deleteWithReasonData?.childData.available_rooms,
                },
              ],
              sun: "1",
              mon: "1",
              tue: "1",
              wed: "1",
              thr: "1",
              fri: "1",
              sat: "1",
            }),
          }
        );

        const data = await response.json();

        if (response.ok && data.success) {
          fetchInfo(property_id?.id, selectedRoom.id, viewType);
        } else {
          showErrorToast(data.message || "An error occurred.");
          console.log("Post request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      } finally {
        setShowConfirmPopupUnblock(false);
        setShowConfirmPopupBlock(false);
      }
      return;
    } else {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/inventories/block/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify({
              room_categories: selectedValues,
            }),
          }
        );

        const data = await response.json();

        if (response.ok && data.success) {
          fetchInfo(property_id?.id, selectedRoom.id);
        } else {
          showErrorToast(data.message || "An error occurred.");
          console.log("Post request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      } finally {
        setShowConfirmPopupUnblock(false);
        setShowConfirmPopupBlock(false);
      }
      return;
    }
  };
  const handleConfirmUnBlockInventory = async () => {
    if (activeApp === "channel_manager") {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/update/inventory/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify({
              from_date: deleteWithReasonData.childData.date,
              to_date: deleteWithReasonData.childData.date,
              status: "Open",
              channels: [
                {
                  channel_id: deleteWithReasonData?.roomData.channel_id,
                  channel_name: deleteWithReasonData?.roomData.channel_name,
                },
              ],
              inventory_data: [
                {
                  room_id: deleteWithReasonData?.childData.room_id,
                  available_rooms:
                    deleteWithReasonData?.childData.available_rooms,
                },
              ],
              sun: "1",
              mon: "1",
              tue: "1",
              wed: "1",
              thr: "1",
              fri: "1",
              sat: "1",
            }),
          }
        );

        const data = await response.json();

        if (response.ok && data.success) {
          showSuccessToast(data.message);
          fetchInfo(property_id?.id, selectedRoom.id, viewType);
        } else {
          showErrorToast(data.message || "An error occurred.");
          console.log("Post request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      } finally {
        setShowConfirmPopupUnblock(false);
        setShowConfirmPopupBlock(false);
      }
      return;
    } else {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/inventories/block/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify({
              room_categories: selectedValues,
            }),
          }
        );

        const data = await response.json();

        if (response.ok && data.success) {
          fetchInfo(property_id?.id, selectedRoom.id);
        } else {
          showErrorToast(data.message || "An error occurred.");
          console.log("Post request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      } finally {
        setShowConfirmPopupUnblock(false);
        setShowConfirmPopupBlock(false);
      }
      return;
    }
  };

  const [channelMappingPopup, setChannelMappingPopup] = useState(false);

  const [selectedChannelMapping, setSelectedChannelMapping] = useState(null);

  const handleChannelMappingPopupShow = (channelId, channelName) => {
    setSelectedChannelMapping({
      channelId: channelId,
      channelName: channelName,
    });
    setChannelMappingPopup(true);
  };

  const [addChannelPopup, setAddChannelPopup] = useState(false);

  const [viewType, setViewType] = useState("channel_view");

  const handleViewTypeChange = (value) => {
    switch (value) {
      case "channel_view":
        setViewType(value);
        fetchInfo(property_id?.id, selectedRoom?.id, "channel_view");
        break;
      case "room_view":
        setViewType(value);
        fetchInfo(property_id?.id, selectedChannel?.channel_id, "room_view");
        break;
      default:
        break;
    }
  };
  const handleInventoryChannelChange = (value) => {
    setSelectedChannel(
      channels.find((channel) => +channel.channel_id === +value)
    );

    fetchInfo(property_id?.id, +value, "room_view");
  };

  // ======   CONTROLS =====
  const [selectedControl, setSelectedControl] = useState([]);
  const handleStopCellCancel = (data) => {
    setSelectedControl(selectedControl.filter((item) => item !== data));
  };

  const controls = ["Stop Sell"];

  const handleControlChange = (value) => {
    setSelectedControl(value);
    const postControls = { is_stop_sell_active: 0, is_drip_feed_active: 0 };

    value.map((item) => {
      if (item === "Stop Cell") {
        postControls.is_stop_sell_active = 1;
      } else if (item === "Drip Feed") {
        postControls.is_drip_feed_active = 1;
      }
    });

    console.log(postControls, "hii");
  };

  const handleStopCellChange = (room, e, outerIndex, innerIndex) => {
    const { name, value } = e.target;
    if (parseInt(value) < 0 || parseInt(value) > 1) {
      showErrorToast("Please enter a value between 0 and 1");
      return;
    }

    setData((prev) => {
      return {
        ...prev,
        rooms: prev.rooms.map((item, oIndex) => {
          if (oIndex === outerIndex) {
            // Match the outerIndex (room)
            return {
              ...item,
              inventory: item.inventory.map((inventoryItem, iIndex) => {
                if (iIndex === innerIndex) {
                  // Match the innerIndex (inventory)
                  return {
                    ...inventoryItem,
                    [name]: value, // Update the specific property
                  };
                }
                return inventoryItem;
              }),
            };
          }
          return item;
        }),
      };
    });
  };

  const handleStopCellSave = async (data, stopSell) => {
    const channel = {
      channel_id: data.channel_id,
      channel_name: data.channel_name,
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/update/inventory/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            from_date: stopSell.date,
            to_date: stopSell.date,
            stop_sell: stopSell.stop_sell,
            channels: [channel],
            inventory_data: [stopSell],
            sun: "1",
            mon: "1",
            tue: "1",
            wed: "1",
            thr: "1",
            fri: "1",
            sat: "1",
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        fetchInfo(property_id?.id, selectedRoom.id, "channel_view");
      } else {
        showErrorToast(data.message || "An error occurred.");
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setShowConfirmPopupUnblock(false);
      setShowConfirmPopupBlock(false);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {data && data.date_range && data.date_range.length > 0 ? (
            <div className="room-types-full-container">
              <div className="room-types-full-container-div">
                <div className="room-type-heading">Inventory</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div>
                    {permissions &&
                    permissions.includes("read_low_inventory_dates") ? (
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`${activeURL}/low-inventory`}
                      >
                        <BgThemeButton children={"Low Inventory Update"} />
                      </Link>
                    ) : (
                      <Tooltip
                        content={`Please contact the admin.`}
                        dismissOnMouseOut
                      >
                        <Link style={{ textDecoration: "none" }}>
                          <BgThemeButtonDisabled
                            children={"Low Inventory Update"}
                          />
                        </Link>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>

              <div className="inventory-full-container">
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="flex  flex_gap_10 align_items_center">
                    <div style={{ minWidth: "150px", maxWidth: "200px" }}>
                      {viewType === "channel_view" && (
                        <SelectBasic
                          options={categories}
                          optionKeyName={"room_name"}
                          size={"medium"}
                          showKeyName={"room_name"}
                          selectedKeyName={"id"}
                          handleSelectTagChange={handleRoomCategoryChange}
                          defaultSelected={selectedRoom}
                        />
                      )}

                      {viewType === "room_view" && (
                        <SelectBasic
                          options={channels}
                          optionKeyName={"channel_name"}
                          size={"medium"}
                          showKeyName={"channel_name"}
                          selectedKeyName={"channel_id"}
                          handleSelectTagChange={handleInventoryChannelChange}
                          defaultSelected={selectedChannel}
                        />
                      )}
                    </div>

                    {activeApp === "channel_manager" ? (
                      <div className={styles.viewTypeContainer}>
                        <button
                          className={styles.viewTypeButtons}
                          style={
                            viewType === "channel_view"
                              ? {
                                  color: "#3968ed",
                                  textDecoration: "underline",
                                }
                              : {}
                          }
                          onClick={() => handleViewTypeChange("channel_view")}
                        >
                          Channel View
                        </button>
                        <button
                          className={styles.viewTypeButtons}
                          style={
                            viewType === "room_view"
                              ? {
                                  color: "#3968ed",
                                  textDecoration: "underline",
                                }
                              : {}
                          }
                          onClick={() => handleViewTypeChange("room_view")}
                        >
                          Room View
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex_gap_10">
                    <div style={{ width: "150px" }}>
                      <SelectMultiple
                        options={controls}
                        size={"medium"}
                        handleSelectTagChange={handleControlChange}
                        placeholder={"Controls"}
                        defaultSelected={selectedControl}
                      />
                    </div>

                    <div style={{ width: "150px" }}>
                      <SelectBasic
                        options={controlsBulk}
                        size={"medium"}
                        handleSelectTagChange={handleBulkControlChange}
                        placeholder={"Bulk Updates"}
                      />
                    </div>
                    <InventoryDatePicker
                      selectedDatePickerDate={selectedDatePickerDate}
                      handleDateChange={handleDateChange}
                      handleDecrementDate={handleDecrementDate}
                      handleIncrementDate={handleIncrementDate}
                    />
                  </div>
                </div>

                <div className="inventory-second-column-2div">
                  <div
                    style={{
                      borderRadius: "6px",
                    }}
                  ></div>
                </div>

                <table
                  style={{ width: "100%" }}
                  className={styles.tableInventory}
                >
                  <thead>
                    <tr>
                      <th>Inventory</th>
                      {data.date_range?.map((date, index) => (
                        <th key={index} className={styles.dateHeaders}>
                          <div className="flex justify_content_center align_items_center">
                            <span
                              style={
                                index === 0
                                  ? {
                                      backgroundColor: "#3968ed",
                                      color: "white",
                                    }
                                  : {}
                              }
                              dangerouslySetInnerHTML={{
                                __html: getMonthDay(date),
                              }}
                              onClick={() => dateClickHandler(date)}
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.rooms?.map((room, roomIndex) => (
                      <tr key={roomIndex}>
                        <td colSpan={data.date_range.length + 1}>
                          <table
                            style={{ width: "100%" }}
                            className={styles.innerTable}
                          >
                            <tbody>
                              <tr>
                                <td className={styles.innerLongHeader}>
                                  {activeApp === "channel_manager" &&
                                  viewType === "channel_view" ? (
                                    <button>{room.channel_name}</button>
                                  ) : (
                                    <button>{room.room_name}</button>
                                  )}
                                </td>
                                {room.inventory
                                  ?.slice(0, 15)
                                  .map((childData, index) => (
                                    <td
                                      key={index}
                                      className={styles.innerTableData}
                                    >
                                      <div className="flex justify_content_center align_items_center">
                                        {childData?.blocked ? (
                                          <span
                                            className={`flex flex_direction_col align_items_center  ${styles["flex-container"]}`}
                                          >
                                            <input
                                              disabled={true}
                                              defaultValue={"00"}
                                              className="inventory-1st-column-inner-input"
                                              style={{
                                                background: "#fb04044d",
                                              }}
                                              type="number"
                                            />
                                            {childData?.sold_rooms} Sold /{" "}
                                            {childData?.hold_rooms} Hold
                                            <span
                                              className={
                                                styles["hover-icon-visible"]
                                              }
                                              onMouseOver={() =>
                                                setHoveredId(index)
                                              }
                                              onMouseOut={() =>
                                                setHoveredId(null)
                                              }
                                            >
                                              {hoveredId === index ? (
                                                <span
                                                  onClick={() => {
                                                    setShowConfirmPopupUnblock(
                                                      true
                                                    );
                                                    if (
                                                      activeApp ===
                                                      "channel_manager"
                                                    ) {
                                                      setDeleteWithReasonData({
                                                        childData: childData,
                                                        roomData: room,
                                                      });
                                                    } else {
                                                      setDeleteWithReasonData(
                                                        childData
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <SvgUnLock />
                                                </span>
                                              ) : (
                                                <SvgLock />
                                              )}
                                              <span
                                                className={styles["tooltip"]}
                                              >
                                                <b>Blocked-By</b>{" "}
                                                {childData.blocked_by}
                                              </span>
                                            </span>
                                          </span>
                                        ) : (
                                          <span
                                            className={`flex flex_direction_col align_items_center  ${styles["flex-container"]}`}
                                          >
                                            <input
                                              disabled={true}
                                              defaultValue={
                                                childData.available_rooms
                                              }
                                              className="inventory-1st-column-inner-input"
                                              type="number"
                                            />
                                            {childData?.sold_rooms} Sold /{" "}
                                            {childData?.hold_rooms} Hold
                                            <span
                                              className={styles["hover-icon"]}
                                            >
                                              <span
                                                onClick={() => {
                                                  setShowConfirmPopupBlock(
                                                    true
                                                  );
                                                  if (
                                                    activeApp ===
                                                    "channel_manager"
                                                  ) {
                                                    setDeleteWithReasonData({
                                                      childData: childData,
                                                      roomData: room,
                                                    });
                                                  } else {
                                                    setDeleteWithReasonData(
                                                      childData
                                                    );
                                                  }
                                                }}
                                              >
                                                <SvgLock />
                                              </span>
                                            </span>
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                  ))}
                              </tr>

                              {selectedControl.includes("Stop Sell") && (
                                <tr>
                                  <td
                                    className={`${styles.innerLongHeader} ${styles.stopSellTableData}`}
                                  >
                                    <span
                                      onClick={() =>
                                        handleStopCellCancel("Stop Sell")
                                      }
                                    >
                                      {" "}
                                      <SvgCancel /> Stop Sell
                                    </span>
                                  </td>
                                  {room.inventory.map((stopSell, index) => (
                                    <>
                                      <td
                                        className={`${styles.innerTableData} ${styles.stopSellTableDataInputSmall}`}
                                        key={stopSell - index}
                                      >
                                        <span
                                          className={`flex flex_direction_col align_items_center  ${styles["flex-container"]}`}
                                        >
                                          <input
                                            className="inventory-1st-column-inner-input"
                                            type="number"
                                            value={stopSell.stop_sell}
                                            name="stop_sell"
                                            onChange={(e) =>
                                              handleStopCellChange(
                                                room,
                                                e,
                                                roomIndex,
                                                index
                                              )
                                            }
                                            min={0}
                                            max={1}
                                          />
                                          <button
                                            onClick={() =>
                                              handleStopCellSave(room, stopSell)
                                            }
                                          >
                                            save
                                          </button>
                                        </span>
                                      </td>
                                    </>
                                  ))}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}

          {/* ================================WORKING HERE================================================== */}

          {showTotalBookings && totalBookingData && (
            <>
              <SliderComponentSkeleton
                show={showTotalBookings}
                setShow={setShowTotalBookings}
                title={
                  <TextDateFormatter
                    as={"div"}
                    date={inventorySelectedDate}
                    showYear={true}
                  />
                }
                smallTitle={`Total Bookings:   ${
                  totalBookingData?.agentContractData?.length +
                  totalBookingData?.holdRooms?.length +
                  totalBookingData?.soldRooms?.length
                }`}
              >
                <InventorySliderComponent
                  totalBookingData={totalBookingData}
                  setShowTotalBookings={setShowTotalBookings}
                  selectedDate={"selectedDate"}
                  inventorySelectedRoomName={inventorySelectedRoomName}
                  inventorySelectedDate={inventorySelectedDate}
                  fetchSoldRoomsData={fetchSoldRoomsData}
                  fetchInfo={fetchInfo}
                />
              </SliderComponentSkeleton>
            </>
          )}
          {/* =============================INVENTORY SLIDER BULK UPDATE====================================================================== */}
          {showBookingSlider && (
            <SliderComponentSkeleton
              show={showBookingSlider}
              setShow={setShowBookingSlider}
              title={`Bulk Update: (${sliderHeading})`}
            >
              {sliderHeading === "Stay Restrictions" && (
                <StayRestrictions
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channels}
                  setShow={setShowBookingSlider}
                />
              )}

              {sliderHeading === "Inventory" && (
                <InventoryUpdate
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channels}
                  setShow={setShowBookingSlider}
                  activeApp={activeApp}
                  viewType={viewType}
                  room_id={selectedRoom.id}
                  channel_id={selectedChannel?.channel_id}
                  fetchInfo={fetchInfo}
                  
                />
              )}

              {sliderHeading === "Rates" && (
                <InventoryRatesUpdate
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channels}
                  setShow={setShowBookingSlider}
                  viewType={viewType}
                  room_id={selectedRoom.id}
                  channel_id={selectedChannel?.channel_id}
                  fetchInfo={fetchInfo}
                />
              )}

              {sliderHeading === "Block" && (
                <>
                  {activeApp === "booking_engine" ? (
                    <InventoryBulkBlock
                      categories={categories}
                      currentDate={currentDate}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseUrl={baseUrl}
                      property_id={property_id}
                      userToken={userToken}
                      setLoader={setLoader}
                      channels={channels}
                      setShow={setShowBookingSlider}
                      permissions={permissions}
                    />
                  ) : (
                    <InventoryBulkBlockChannelManager
                      categories={categories}
                      currentDate={currentDate}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseUrl={baseUrl}
                      property_id={property_id}
                      userToken={userToken}
                      setLoader={setLoader}
                      channels={channels}
                      setShow={setShowBookingSlider}
                      permissions={permissions}
                      viewType={viewType}
                      room_id={selectedRoom.id}
                      channel_id={selectedChannel?.channel_id}
                    />
                  )}
                </>
              )}

              {sliderHeading === "Unblock" && (
                <>
                  {activeApp === "booking_engine" ? (
                    <InventoryUnblock
                      categories={categories}
                      currentDate={currentDate}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseUrl={baseUrl}
                      property_id={property_id}
                      userToken={userToken}
                      setLoader={setLoader}
                      channels={channels}
                      setShow={setShowBookingSlider}
                      permissions={permissions}
                      fetchInfo={fetchInfo}

                    />
                  ) : (
                    <InventoryUnblockChannelManager
                      categories={categories}
                      currentDate={currentDate}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseUrl={baseUrl}
                      property_id={property_id}
                      userToken={userToken}
                      setLoader={setLoader}
                      channels={channels}
                      setShow={setShowBookingSlider}
                      permissions={permissions}
                      fetchInfo={fetchInfo}
                      viewType={viewType}
                      room_id={selectedRoom.id}
                      channel_id={selectedChannel?.channel_id}
                    />
                  )}
                </>
              )}

              {(sliderHeading === "Stop Sell Active" ||
                sliderHeading === "Stop Sell Inactive") && (
                <>
                  <InventoryStopSell
                    categories={categories}
                    currentDate={currentDate}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    baseUrl={baseUrl}
                    property_id={property_id}
                    userToken={userToken}
                    setLoader={setLoader}
                    channels={channels}
                    type={sliderHeading}
                    setShow={setShowBookingSlider}
                    viewType={viewType}
                    room_id={selectedRoom.id}
                    channel_id={selectedChannel?.channel_id}
                    
                    activeApp={activeApp}
                    fetchInfo={fetchInfo}
                  />
                </>
              )}
            </SliderComponentSkeleton>
          )}

          {/* ======= block Unblock popup confirm popup======== */}

          {showConfirmPopupBlock && (
            <ConfirmPopup
              setShowPopup={setShowConfirmPopupBlock}
              showPopup={showConfirmPopupBlock}
              heading={"Are you sure?"}
              subHeading={"You Want to Block?"}
              handleConfirmClick={handleConfirmBlockInventory}
            />
          )}
          {showConfirmPopupUnblock && (
            <ConfirmPopup
              setShowPopup={setShowConfirmPopupUnblock}
              showPopup={showConfirmPopupUnblock}
              heading={"Are you sure?"}
              subHeading={"You Want to Unblock?"}
              handleConfirmClick={handleConfirmUnBlockInventory}
            />
          )}

          {/* ======= end of block Unblock popup confirm popup======== */}

          {/* ======= channel mapping popup======== */}

          {addChannelPopup && (
            <InventoryAddChannel
              show={addChannelPopup}
              setShow={setAddChannelPopup}
              fetchInfo={fetchInfo}
              roomId={selectedRoom.id}
              propertyId={property_id.id}
            />
          )}
        </>
      )}
    </>
  );
};

export default InventoryCommon;
