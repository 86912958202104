import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import { CustomReportTable, CustomReportTableCell, CustomReportTableRow } from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportOutlet = ({ loader, data }) => {
    return (
        <>
            {loader ? (
                <Loader />
            ) : (
                <div id="receipt" className="room-types-full-container reports-new-background">
                    <div className="room-types-full-container-div">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <div className="room-type-heading">Outlet Reports</div>
                            {data && data.length > 0 && (
                                <div className="flex flex_gap_15" id="doNotDownload">
                                    <ButtonToDownloadTablePDF
                                        itemToDownloadID={"receipt"}
                                        downloadFileName={"Outlet_reports"}
                                        ignoreFromPrint={"doNotDownload"}
                                    // pdfStyles={

                                    // }
                                    />
                                    <DownloadCSVButton itemToDownloadID={"receipt"}
                                        downloadFileName={"Outlet_report"} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="receipt">
                        {data && data.length > 0 ? (
                            <>
                                <CustomReportTable
                                    headerStyles={{ borderBottom: "1px solid #CCCCCC" }}
                                    showCheckbox={false}
                                    headings={["Sr.No", "Name", "Total Amount", "Tax Amount", "Grand Total"]}
                                >
                                    {data && data.length > 0 && data?.map(
                                        (items, index) => {
                                            return (
                                                <CustomReportTableRow>
                                                    <CustomReportTableCell>
                                                        {index + 1}
                                                    </CustomReportTableCell>
                                                    <CustomReportTableCell>
                                                        {items.order_by}
                                                    </CustomReportTableCell>

                                                    <CustomReportTableCell>
                                                        {items.kot_total_amount}
                                                    </CustomReportTableCell>
                                                    <CustomReportTableCell>
                                                        {items.kot_tax_amount}
                                                    </CustomReportTableCell>
                                                    <CustomReportTableCell>
                                                        {items.kot_grand_total}
                                                    </CustomReportTableCell>
                                                </CustomReportTableRow>
                                            );
                                        }
                                    )}


                                </CustomReportTable>
                            </>
                        ) : (
                            <NoDataFound />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default ReportOutlet
