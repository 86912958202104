import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useRef } from "react";
import SwitchApp from "../switchApp/SwitchApp";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { checkIsAuthenticatedFalse } from "../../reduxToolkit/Slice/isAuthenticatedSlice";
import styles from "./navigationDropdown.module.scss";
import ConfirmPopup from "../NewCustomComp/confirmPopup/ConfirmPopup";
import SwitchPropertyPopup from "../SwitchPropertyPopup/SwitchPropertyPopup";

const NavigationDropDown = ({
  property,
  user,
  userToken,
  hideDropDownOptions,
  activeApp,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [switchPropertyShow, setSwitchPropertyShow] = useState(false);

  const handleSwitchPropertyClick = (e) => {
    e.stopPropagation();
    setSwitchPropertyShow(true);
  };
  const sidebarRef = useRef(null);

  const logoutHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/logout`, {
      method: "POST",
      headers: { Authorization: `Bearer ${userToken} ` },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          navigate("/login");
          window.location.reload();
          // navigate("/");
          showSuccessToast(data.message);

          setIsAuthenticated(false);
          dispatch(checkIsAuthenticatedFalse(false));
          localStorage.clear();
        } else {
          navigate("/login");
          showErrorToast("Something Went Wrong! Please Try again later");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sidebarRef]);

  return (
    <>
      <div onClick={(e)=>e.stopPropagation()}>
        <div
          className={styles.container}
          onClick={() => setShowDropDown(!showDropDown)}
          ref={sidebarRef}
        >
          <span className={styles.property_name}>
            {hideDropDownOptions
              ? `Hii ${user?.name ? user?.name : "Welcome!"}`
              : property?.property_name}
          </span>

          <span className={showDropDown ? styles.rotate : styles.iconContainer}>
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={showDropDown ? styles.rotate : styles.iconContainer}
            >
              <path d="M11 1L6 6L1 1" stroke="#3968ed" strokeWidth="2" />
            </svg>
          </span>

          {showDropDown && (
            <div
              className={`${styles["user_profile_dropdown_content"]} ${
                hideDropDownOptions ? styles.small : ""
              } `}
              onClick={(e) => e.stopPropagation()}
            >
              <div>
                <ul
                  style={{ listStyle: "none", lineHeight: "25px" }}
                  className={`${styles["user_profile_options_list"]} `}
                >
                  {hideDropDownOptions ? null : (
                    <>
                      <li className={styles.user_name}>Hii {user?.name} </li>
                      <li className={styles.links}>
                        <Link to="/settings/profile/new">Profile</Link>
                      </li>

                      <li  onClick={(e)=> e.stopPropagation()} className={styles.links}>
                      <SwitchPropertyPopup />
                      </li>
                      <li className={styles.links}>
                        <Link
                          to={
                            activeApp === "pms"
                              ? "/settings/profile/new"
                              : "/channel-manager/settings/channel"
                          }
                        >
                          Settings
                        </Link>
                      </li>

                      <li className={styles.links}>
                        <SwitchApp />
                      </li>
                    </>
                  )}

                  <li
                    className={styles.links}
                    style={{ color: "red" }}
                    onClick={() => setShowLogout(true)}
                  >
                    Log Out
                  </li>
                </ul>
              </div>
            </div>
          )}
          
        </div>
      </div>







      {showLogout && (
        <>
          <ConfirmPopup
            reasonField={false}
            heading={"Are you sure ?"}
            subHeading={"You want to logout ?"}
            showPopup={showLogout}
            setShowPopup={setShowLogout}
            handleConfirmClick={logoutHandle}
          />
        </>
      )}
     
    </>
  );
};

export default NavigationDropDown;
