import React from "react";
import SettingsBanner from "./SettingsBanner";
import SettingsContent from "./SettingsContent";

const Settings = () => {
  return (
    <>
      {/* <SettingsBanner /> */}
      <div className="main_container" style={{ marginTop: "25px" }}>
      <SettingsContent />
      </div>
    </>
  );
};

export default Settings;
