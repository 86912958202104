import React from "react";
import styles from "./search.module.scss";

const getHighlightedText = (text, highlight) => {
  const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
  return parts?.map((part, index) =>
    part?.toLowerCase().replace(/\s/g, "") ===
    highlight?.toLowerCase().replace(/\s/g, "") ? (
      <span key={index} style={{ color: "#3968ED" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const SearchSelect = ({
  error,
  errorText = "Error",
  label,
  // options = [
  //   "option1",
  //   "option2",
  //   "option3",
  //   "option4",
  //   "option5",
  //   "option6",
  //   "option7",
  //   "option8", 
  //   "option9",
  //   "option10",
  // ],
  defaultSelected="option 1",
  options = [{name:"option 1"},{name:"option 2"} ,{name:"option 3"}    ],
  optionKeyName = "name",
  selectedKeyName = "name",
  name="search",
  size = "small",
  required = true,
  disabled = false,
  handleOptionChange = (data) => {console.log(data)},
}) => {
  const [searchTerm, setSearchTerm] = React.useState(defaultSelected);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState(options);
  const [focusedIndex, setFocusedIndex] = React.useState(-1);
  const filterContainerRef = React.useRef(null);
  const searchContainerRef = React.useRef(null); 

  const getSvgSizeSearch = () => {
    switch (size) {
      case "large":
        return 16;
      case "medium":
        return 15;
      case "small":
        return 12;
      default:
        return 14;
    }
  };

  const handleSearchInput = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    if (!optionKeyName) {
     
      setSearchResults(
        options.filter((option) => option.replace(/\s/g, "").includes(value))
      );
      setSearchTerm(e.target.value);
      setShowDropDown(true);
    } else {
      setSearchResults(
        options.filter((option) => option[optionKeyName].replace(/\s/g, "").includes(value))
      );
      setSearchTerm(e.target.value);
      setShowDropDown(true);

    }
  };

  const handleSearchInputFocus = () => {
    setShowDropDown(true);
  };

  const handleOptionClick = (option) => {
    if (!optionKeyName){
      setSearchTerm(option);
      setShowDropDown(false);
      handleOptionChange(option)
    }else{
      setSearchTerm(option[optionKeyName]);
      setShowDropDown(false); 
      if (selectedKeyName){
        handleOptionChange(option[selectedKeyName])
      }else{
        handleOptionChange(option)
      }


    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      setSearchTerm("");
      return;
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < searchResults?.length - 1 ? prevIndex + 1 : prevIndex
      );
      return;
    }
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      return;
    }
    if (e.key === "Enter" && focusedIndex !== -1) {
      handleOptionClick(searchResults[focusedIndex]);
    }
  };

  React.useEffect(() => {
    // Scroll to the focused item in the parent container
    if (filterContainerRef.current && focusedIndex !== -1) {
      const listItem = filterContainerRef.current.childNodes[focusedIndex];
      if (listItem) {
        listItem.scrollIntoView({ block: "nearest" });
      }
    }
  }, [focusedIndex]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearchInputBlur = () => {
   if(!optionKeyName){
    if (!options.includes(searchTerm)) {
      setSearchTerm("");
    }
   }else{
    if (!options.some(option => option[optionKeyName] === searchTerm)) {
      setSearchTerm("");
    }
   }
  };

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
      ref={searchContainerRef}
    >
      {label && (
        <label
          className={
            size === "small"
              ? styles.small
              : size === "medium"
              ? styles.medium
              : styles.large
          }
        >
          {label} {required && <sup style={{ color: "red" }}>*</sup>}
        </label>
      )}
      <div
        className={`${styles.searchContainer} ${
          size === "small"
            ? styles.small
            : size === "medium"
            ? styles.medium
            : styles.large
        } 
        ${disabled ? styles.disabled : ""}
        ${error ? styles.error : ""}`}
        style={{ borderColor: showDropDown ? "#3968ED" : null }}
      >
        <span className={styles.searchIcon}>
          <svg
            width={getSvgSizeSearch(size)}
            height={getSvgSizeSearch(size)}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0667 12.5L6.86667 8.3C6.53333 8.56667 6.15 8.77778 5.71667 8.93333C5.28333 9.08889 4.82222 9.16667 4.33333 9.16667C3.12222 9.16667 2.09722 8.74722 1.25833 7.90833C0.419445 7.06944 0 6.04444 0 4.83333C0 3.62222 0.419445 2.59722 1.25833 1.75833C2.09722 0.919445 3.12222 0.5 4.33333 0.5C5.54444 0.5 6.56944 0.919445 7.40833 1.75833C8.24722 2.59722 8.66667 3.62222 8.66667 4.83333C8.66667 5.32222 8.58889 5.78333 8.43333 6.21667C8.27778 6.65 8.06667 7.03333 7.8 7.36667L12 11.5667L11.0667 12.5ZM4.33333 7.83333C5.16667 7.83333 5.875 7.54167 6.45833 6.95833C7.04167 6.375 7.33333 5.66667 7.33333 4.83333C7.33333 4 7.04167 3.29167 6.45833 2.70833C5.875 2.125 5.16667 1.83333 4.33333 1.83333C3.5 1.83333 2.79167 2.125 2.20833 2.70833C1.625 3.29167 1.33333 4 1.33333 4.83333C1.33333 5.66667 1.625 6.375 2.20833 6.95833C2.79167 7.54167 3.5 7.83333 4.33333 7.83333Z"
              fill="#858585"
            />
          </svg>
        </span>
        <input
          type="text"
          name
          disabled={disabled}
          value={searchTerm}
          placeholder="Search..."
          onChange={handleSearchInput}
          onFocus={handleSearchInputFocus}
          onKeyDown={handleKeyDown}
          onBlur={handleSearchInputBlur}
        />
      </div>
      {error && errorText && (
        <div className={styles.error_text}>{errorText}</div>
      )}
      {showDropDown && (
        <div className={styles.dropdownContainer} ref={filterContainerRef}>
          {searchResults.length === 0 ? (
            <div className={styles.dropdownItem}>No data found</div>
          ) : (
            searchResults.map((item, index) => (
              <div
                key={index}
                className={`${styles.dropdownItem}    ${
                  focusedIndex === index ? styles.focused : ""
                }     `}
                onClick={() => handleOptionClick(item)}
              >
                { !optionKeyName ? getHighlightedText(item, searchTerm)   : getHighlightedText(item[optionKeyName], searchTerm)}  
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export const SearchSelectMultiple = ({
  error,
  errorText = "Error",
  label,
  defaultSelected = "option 1",
  options = [{ name: "option 1" }, { name: "option 2" }, { name: "option 3" }],
  optionKeyName = "name",
  selectedKeyName = "name",
  size = "small",
  required = true,
  disabled = false,
  handleOptionChange = (data) => { console.log(data) },
}) => {
  const [searchTerm, setSearchTerm] = React.useState(defaultSelected);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState(options);
  const [focusedIndex, setFocusedIndex] = React.useState(-1);
  const filterContainerRef = React.useRef(null);
  const searchContainerRef = React.useRef(null);

  const getSvgSizeSearch = () => {
    switch (size) {
      case "large":
        return 16;
      case "medium":
        return 15;
      case "small":
        return 12;
      default:
        return 14;
    }
  };

  const handleSearchInput = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    if (!optionKeyName) {
      setSearchResults(
        options.filter((option) => option.replace(/\s/g, "").includes(value))
      );
      setSearchTerm(e.target.value);
      setShowDropDown(true);
    } else {
      setSearchResults(
        options.filter((option) => option[optionKeyName].replace(/\s/g, "").includes(value))
      );
      setSearchTerm(e.target.value);
      setShowDropDown(true);
    }
  };

  const handleSearchInputFocus = () => {
    setShowDropDown(true);
    setSearchResults(options)
  };

  const handleOptionClick = (option) => {
    let updatedSelectedOptions;
    if (optionKeyName) {
      const optionValue = option[optionKeyName];
      if (selectedOptions.includes(optionValue)) {
        updatedSelectedOptions = selectedOptions.filter(
          (item) => item !== optionValue
        );
      } else {
        updatedSelectedOptions = [...selectedOptions, optionValue];
      }
    } else {
      if (selectedOptions.includes(option)) {
        updatedSelectedOptions = selectedOptions.filter(
          (item) => item !== option
        );
      } else {
        updatedSelectedOptions = [...selectedOptions, option];
      }
    }
    setSelectedOptions(updatedSelectedOptions);
    handleOptionChange(updatedSelectedOptions);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      setSearchTerm("");
      return;
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < searchResults?.length - 1 ? prevIndex + 1 : prevIndex
      );
      return;
    }
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      return;
    }
    if (e.key === "Enter" && focusedIndex !== -1) {
      handleOptionClick(searchResults[focusedIndex]);
    }
  };

  React.useEffect(() => {
    // Scroll to the focused item in the parent container
    if (filterContainerRef.current && focusedIndex !== -1) {
      const listItem = filterContainerRef.current.childNodes[focusedIndex];
      if (listItem) {
        listItem.scrollIntoView({ block: "nearest" });
      }
    }
  }, [focusedIndex]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        filterContainerRef.current &&
        !searchContainerRef.current.contains(event.target) &&
        !filterContainerRef.current.contains(event.target)
      ) {
        setShowDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearchInputBlur = () => {
    if (!optionKeyName) {
      if (!options.includes(searchTerm)) {
        setSearchTerm("");
      }
    } else {
      if (!options.some((option) => option[optionKeyName] === searchTerm)) {
        setSearchTerm("");
      }
    }
  };

  const isSelected = (option) => {
    if (optionKeyName) {
      return selectedOptions.includes(option[optionKeyName]);
    }
    return selectedOptions.includes(option);
  };

 

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
      ref={searchContainerRef}
    >
      {label && (
        <label
          className={
            size === "small"
              ? styles.small
              : size === "medium"
              ? styles.medium
              : styles.large
          }
        >
          {label} {required && <sup style={{ color: "red" }}>*</sup>}
        </label>
      )}
      <div
        className={`${styles.searchContainer} ${
          size === "small"
            ? styles.small
            : size === "medium"
            ? styles.medium
            : styles.large
        }
        ${disabled ? styles.disabled : ""}
        ${error ? styles.error : ""}`}
        style={{ borderColor: showDropDown ? "#3968ED" : null }}
      >
        <span className={styles.searchIcon}>
          <svg
            width={getSvgSizeSearch(size)}
            height={getSvgSizeSearch(size)}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0667 12.5L6.86667 8.3C6.53333 8.56667 6.15 8.77778 5.71667 8.93333C5.28333 9.08889 4.82222 9.16667 4.33333 9.16667C3.12222 9.16667 2.09722 8.74722 1.25833 7.90833C0.419445 7.06944 0 6.04444 0 4.83333C0 3.62222 0.419445 2.59722 1.25833 1.75833C2.09722 0.919445 3.12222 0.5 4.33333 0.5C5.54444 0.5 6.56944 0.919445 7.40833 1.75833C8.24722 2.59722 8.66667 3.62222 8.66667 4.83333C8.66667 5.32222 8.58889 5.78333 8.43333 6.21667C8.27778 6.65 8.06667 7.03333 7.8 7.36667L12 11.5667L11.0667 12.5ZM4.33333 7.83333C5.16667 7.83333 5.875 7.54167 6.45833 6.95833C7.04167 6.375 7.33333 5.66667 7.33333 4.83333C7.33333 4 7.04167 3.29167 6.45833 2.70833C5.875 2.125 5.16667 1.83333 4.33333 1.83333C3.5 1.83333 2.79167 2.125 2.20833 2.70833C1.625 3.29167 1.33333 4 1.33333 4.83333C1.33333 5.66667 1.625 6.375 2.20833 6.95833C2.79167 7.54167 3.5 7.83333 4.33333 7.83333Z"
              fill="#858585"
            />
          </svg>
        </span>
        <input
          className={styles.searchInput}
          type="text"
          disabled={disabled}
          value={searchTerm}
          placeholder="Search..."
          onChange={handleSearchInput}
          onFocus={handleSearchInputFocus}
          onKeyDown={handleKeyDown}
          onBlur={handleSearchInputBlur}
          name
        />
      </div>
      {error && errorText && (
        <div className={styles.error_text}>{errorText}</div>
      )}
      {showDropDown && (
        <div className={styles.dropdownContainer} ref={filterContainerRef}>
          {searchResults.length === 0 ? (
            <div className={styles.dropdownItem}>No data found</div>
          ) : (
            searchResults.map((item, index) => (
              <div
                key={index}
                className={`${styles.dropdownItem} ${styles.dropdownItemMultiple} ${
                  focusedIndex === index ? styles.focused : ""
                } ${isSelected(item) ? styles.selected : ""}`}
                onClick={() => handleOptionClick(item)}
              >
                <span>
                  <input
                    type="checkbox"
                    checked={isSelected(item)}
                    readOnly
                  />
                </span>
                <span>
                  {!optionKeyName
                    ? getHighlightedText(item, searchTerm)
                    :getHighlightedText(item[optionKeyName], searchTerm) }
                </span>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export const SearchOrAdd = ({
  error,
  errorText = "Error",
  label,
  // options = [
  //   "option1",
  //   "option2",
  //   "option3",
  //   "option4",
  //   "option5",
  //   "option6",
  //   "option7",
  //   "option8", 
  //   "option9",
  //   "option10",
  // ],
  defaultSelected,
  options,
  optionKeyName,
  selectedKeyName,
  name,
  size,
  required,
  disabled,
  handleOptionChange = (data) => { console.log(data) },
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  React.useEffect(()=>{
    setSearchTerm(defaultSelected)
  },[defaultSelected])
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState(options);
  const [focusedIndex, setFocusedIndex] = React.useState(-1);
  const filterContainerRef = React.useRef(null);
  const searchContainerRef = React.useRef(null);

  const getSvgSizeSearch = () => {
    switch (size) {
      case "large":
        return 16;
      case "medium":
        return 15;
      case "small":
        return 12;
      default:
        return 14;
    }
  };

  const handleSearchInput = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    if (!optionKeyName) {

      setSearchResults(
        options.filter((option) => option.replace(/\s/g, "").includes(value))
      );
      setSearchTerm(e.target.value);
      setShowDropDown(true);
    } else {
      setSearchResults(
        options.filter((option) => option[optionKeyName].replace(/\s/g, "").includes(value))
      );
      setSearchTerm(e.target.value);
      setShowDropDown(true);

    }
    setSearchTerm(e.target.value);
    handleOptionChange(e.target.value)
  };

  const handleSearchInputFocus = () => {
    setShowDropDown(true);
  };

  const handleOptionClick = (option) => {
    if (!optionKeyName) {
      setSearchTerm(option);
      setShowDropDown(false);
      handleOptionChange(option)
    } else {
      setSearchTerm(option[optionKeyName]);
      setShowDropDown(false);
      if (selectedKeyName) {
        handleOptionChange(option[selectedKeyName])
      } else {
        handleOptionChange(option)
      }


    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      setSearchTerm("");
      return;
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < searchResults?.length - 1 ? prevIndex + 1 : prevIndex
      );
      return;
    }
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      return;
    }
    if (e.key === "Enter" && focusedIndex !== -1) {
      handleOptionClick(searchResults[focusedIndex]);
    }
    if (e.key === "Enter") {
      handleOptionChange(searchTerm)
    }
  };

  React.useEffect(() => {
    // Scroll to the focused item in the parent container
    if (filterContainerRef.current && focusedIndex !== -1) {
      const listItem = filterContainerRef.current.childNodes[focusedIndex];
      if (listItem) {
        listItem.scrollIntoView({ block: "nearest" });
      }
    }
  }, [focusedIndex]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearchInputBlur = () => {
    // if (!optionKeyName) {
    //   if (!options.includes(searchTerm)) {
    //     setSearchTerm("");
    //   }
    // } else {
    //   if (!options.some(option => option[optionKeyName] === searchTerm)) {
    //     setSearchTerm("");
    //   }
    // }
  };

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
      ref={searchContainerRef}
    >
      {label && (
        <label
          className={
            size === "small"
              ? styles.small
              : size === "medium"
                ? styles.medium
                : styles.large
          }
        >
          {label} {required && <sup style={{ color: "red" }}>*</sup>}
        </label>
      )}
      <div
        className={`${styles.searchContainer} ${size === "small"
          ? styles.small
          : size === "medium"
            ? styles.medium
            : styles.large
          } 
        ${disabled ? styles.disabled : ""}
        ${error ? styles.error : ""}`}
        style={{ borderColor: showDropDown ? "#3968ED" : null }}
      >
        <span className={styles.searchIcon}>
          <svg
            width={getSvgSizeSearch(size)}
            height={getSvgSizeSearch(size)}
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0667 12.5L6.86667 8.3C6.53333 8.56667 6.15 8.77778 5.71667 8.93333C5.28333 9.08889 4.82222 9.16667 4.33333 9.16667C3.12222 9.16667 2.09722 8.74722 1.25833 7.90833C0.419445 7.06944 0 6.04444 0 4.83333C0 3.62222 0.419445 2.59722 1.25833 1.75833C2.09722 0.919445 3.12222 0.5 4.33333 0.5C5.54444 0.5 6.56944 0.919445 7.40833 1.75833C8.24722 2.59722 8.66667 3.62222 8.66667 4.83333C8.66667 5.32222 8.58889 5.78333 8.43333 6.21667C8.27778 6.65 8.06667 7.03333 7.8 7.36667L12 11.5667L11.0667 12.5ZM4.33333 7.83333C5.16667 7.83333 5.875 7.54167 6.45833 6.95833C7.04167 6.375 7.33333 5.66667 7.33333 4.83333C7.33333 4 7.04167 3.29167 6.45833 2.70833C5.875 2.125 5.16667 1.83333 4.33333 1.83333C3.5 1.83333 2.79167 2.125 2.20833 2.70833C1.625 3.29167 1.33333 4 1.33333 4.83333C1.33333 5.66667 1.625 6.375 2.20833 6.95833C2.79167 7.54167 3.5 7.83333 4.33333 7.83333Z"
              fill="#858585"
            />
          </svg>
        </span>
        <input
          type="text"
          name
          disabled={disabled}
          value={searchTerm}
          placeholder="Search..."
          onChange={handleSearchInput}
          onFocus={handleSearchInputFocus}
          onKeyDown={handleKeyDown}
          onBlur={handleSearchInputBlur}
        />
      </div>
      {error && errorText && (
        <div className={styles.error_text}>{errorText}</div>
      )}
      {showDropDown && (
        <div className={styles.dropdownContainer} ref={filterContainerRef}>
          {searchResults.length === 0 ? (
            <div className={styles.dropdownItem}>No data found</div>
          ) : (
            searchResults.map((item, index) => (
              <div
                key={index}
                className={`${styles.dropdownItem}    ${focusedIndex === index ? styles.focused : ""
                  }     `}
                onClick={() => handleOptionClick(item)}
              >
                {!optionKeyName ? getHighlightedText(item, searchTerm) : getHighlightedText(item[optionKeyName], searchTerm)}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}














