import React, { useState, useEffect, useCallback } from "react";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import styles from "./websites.module.scss";
import stylesTwo from "./websiteEditOffers.module.scss";
import DateRangePicker from "../../Components/NewCustomComp/DateRangePicker/DateRangePicker";
import TimePicker from "../../Components/NewCustomComp/timePicker/TimePicker";
import {
  BgOutlineBlackButton,
  BgThemeButton,
} from "../../Components/UI/Buttons";
import { PrimaryInput } from "../../Components/NewCustomComp/Inputs/Inputs";
import { useSelector } from "react-redux";
import { getBaseUrl } from "../../utils/utils";
import DaySelectionWeekDays from "../../Components/daySelectionWeekDays/DaySelectionWeekDays";
import { SvgCancel } from "../../assets/svgIcons";
import Loader from "../../Components/UI/Loader/Loader";

const CreateWebsiteDiscount = ({
  show,
  setShow,
  fetchDiscounts,
  rooms,
  newPromotionData,
  setNewPromotionData,
}) => {
  const baseUrl = getBaseUrl();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value)?.id;
  const [mealPlans, setMealPlans] = useState([]);
  useEffect(() => {
    if (property_id) {
      fetchMealPlans(property_id);
    }
  }, [property_id]);
  const fetchMealPlans = async (propertyId) => {
    try {
      setLoader(true); // Set loading before sending API request
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setMealPlans(data.plans);
      setNewPromotionData((prevData) => ({
        ...prevData,
        all_conditions: {
          ...prevData.all_conditions,
          selected_rate_plans: data.plans.map((room) => room.meal_plan_type),
        },
      }));

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const handleRatePlanChange = (ratePlan) => {
    const updatedSelectedRatePlans =
      newPromotionData.all_conditions.selected_rate_plans.includes(ratePlan)
        ? newPromotionData.all_conditions.selected_rate_plans.filter(
            (plan) => plan !== ratePlan
          )
        : [...newPromotionData.all_conditions.selected_rate_plans, ratePlan];

    setNewPromotionData({
      ...newPromotionData,
      all_conditions: {
        ...newPromotionData.all_conditions,
        selected_rate_plans: updatedSelectedRatePlans,
      },
    });
  };

  const handleRoomChange = (ratePlan) => {
    const updatedSelectedRatePlans =
      newPromotionData.all_conditions.selected_rooms.includes(ratePlan)
        ? newPromotionData.all_conditions.selected_rooms.filter(
            (plan) => plan !== ratePlan
          )
        : [...newPromotionData.all_conditions.selected_rooms, ratePlan];

    setNewPromotionData({
      ...newPromotionData,
      all_conditions: {
        ...newPromotionData.all_conditions,
        selected_rooms: updatedSelectedRatePlans,
      },
    });
  };

  const handleStayDatesNew = useCallback((dates) => {
    const dayIndexMap = {
      sun: 1,
      mon: 2,
      tue: 3,
      wed: 4,
      thu: 5,
      fri: 6,
      sat: 7,
    };

    const result = Object.entries(dates)
      .filter(([day, value]) => value === 1)
      .map(([day]) => dayIndexMap[day] || null)
      .filter((dayIndex) => dayIndex !== null);

    setNewPromotionData((prevState) => ({
      ...prevState,
      all_conditions: {
        ...prevState.all_conditions,
        stay_dates: result,
      },
    }));
  }, []);

  const key = JSON.parse(localStorage.getItem("selectedProperty"));

  const postData = async () => {
    const myHeaders = new Headers();

    if (
      newPromotionData?.amount === 0 ||
      newPromotionData?.amount === "" ||
      !newPromotionData?.amount
    ) {
      return showErrorToast("Please enter valid discount Amount / Percentage");
    }

    if (!newPromotionData.start_date || !newPromotionData.end_date) {
      return showErrorToast("Please enter valid date");
    }

    if (!newPromotionData.start_time || !newPromotionData.end_time) {
      return showErrorToast("Please enter valid time");
    }
    if (
      newPromotionData?.method === "code" &&
      !newPromotionData?.discount_code
    ) {
      return showErrorToast("Please enter discount code");
    }
    if (newPromotionData?.all_conditions?.stay_dates.length === 0) {
      return showErrorToast("Please select stay dates");
    }

    if (
      newPromotionData?.all_conditions?.rate_plans === "selected" &&
      newPromotionData?.all_conditions?.selected_rate_plans.length === 0
    ) {
      return showErrorToast("Please select rooms");
    }
    if (
      newPromotionData?.all_conditions?.rooms === "selected" &&
      newPromotionData?.all_conditions?.selected_rooms.length === 0
    ) {
      return showErrorToast("Please select rooms");
    }

    if (!newPromotionData?.all_conditions?.promotion_name) {
      return showErrorToast("Please enter promotion name");
    }

    const raw = {
      ...newPromotionData,
      property_id: property_id,
    };

    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/discounts/save/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
            key: key.token,
          },
          method: "POST",
          body: JSON.stringify({
            ...raw,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setShow(false);
        fetchDiscounts();
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <div className={styles.website_discount_container}>
        <div className="room-type-heading flex justify_content_between">
          Create Discount
          <span className="cursor-pointer" onClick={() => setShow(false)}>
            <SvgCancel color={"#333333"} height={"18px"} width={"18px"} />
          </span>
        </div>
        <hr className="mt-10" />
        <div className={styles.discounts_container}>
          {loader ? (
            <div className="w-100 h-100 flex justify_content_center align_items-center">
              <Loader />
            </div>
          ) : (
            <>
              <div className={stylesTwo.container} style={{ height: "400px" }}>
                <div className="mb-10">
                  <h6>Which rooms?</h6>
                  <div className="flex flex_gap_5">
                    <input
                      onChange={() =>
                        setNewPromotionData({
                          ...newPromotionData,
                          all_conditions: {
                            ...newPromotionData.all_conditions,
                            rooms: "all",
                          },
                        })
                      }
                      value="all"
                      checked={newPromotionData.all_conditions.rooms === "all"}
                      type="radio"
                      id="all_rooms"
                    />
                    <label htmlFor="all_rooms">
                      All rooms within all selected rate plans
                    </label>{" "}
                  </div>

                  <div className="flex flex_gap_5">
                    <input
                      onChange={() =>
                        setNewPromotionData({
                          ...newPromotionData,
                          all_conditions: {
                            ...newPromotionData.all_conditions,
                            rooms: "selected",
                          },
                        })
                      }
                      value="selected"
                      checked={
                        newPromotionData.all_conditions.rooms === "selected"
                      }
                      type="radio"
                      id="select_rooms"
                    />
                    <label htmlFor="select_rooms">Select rooms</label>{" "}
                  </div>

                  {newPromotionData.all_conditions.rooms === "selected" && (
                    <div className="mt_10">
                      <h6 htmlFor="">Select Room </h6>
                      {rooms.map((room) => (
                        <div className="flex flex_gap_5" key={room.id}>
                          {" "}
                          <input
                            onChange={() => handleRoomChange(room.id)}
                            checked={newPromotionData.all_conditions.selected_rooms.includes(
                              room.id
                            )}
                            type="checkbox"
                            id={`room_${room.id}`}
                          />
                          <label htmlFor={`room_${room.id}`}>
                            {room.room_name}
                          </label>{" "}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mb-10">
                  <div>
                    <h6>Which rate plans will this promotion apply to?</h6>
                    <div className="flex flex_gap_5">
                      <input
                        onChange={() =>
                          setNewPromotionData({
                            ...newPromotionData,
                            all_conditions: {
                              ...newPromotionData.all_conditions,
                              rate_plans: "all",
                            },
                          })
                        }
                        value={newPromotionData.all_conditions.rate_plans}
                        checked={
                          newPromotionData.all_conditions.rate_plans === "all"
                        }
                        type="radio"
                        id="all_rate_plans"
                      />
                      <label htmlFor="all_rate_plans">All rate plans</label>
                    </div>

                    <div className="flex flex_gap_5">
                      <input
                        onChange={() =>
                          setNewPromotionData({
                            ...newPromotionData,
                            all_conditions: {
                              ...newPromotionData.all_conditions,
                              rate_plans: "selected",
                            },
                          })
                        }
                        value={newPromotionData.all_conditions.rate_plans}
                        checked={
                          newPromotionData.all_conditions.rate_plans ===
                          "selected"
                        }
                        type="radio"
                        id="select-rate-plan"
                      />
                      <label htmlFor="select-rate-plan">
                        Select rate plans
                      </label>
                    </div>
                  </div>

                  <div>
                    {newPromotionData.all_conditions.rate_plans ===
                      "selected" && (
                      <>
                        <div className="mt_10">
                          <h6 htmlFor="">Select rate plans</h6>

                          {mealPlans.map((mealPlan) => (
                            <div className="flex flex_gap_5" key={mealPlan.id}>
                              <input
                                onChange={() =>
                                  handleRatePlanChange(mealPlan.meal_plan_type)
                                }
                                checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                  mealPlan.meal_plan_type
                                )}
                                type="checkbox"
                                id={`rate_plan_${mealPlan.meal_plan_type}`}
                              />
                              <label
                                htmlFor={`rate_plan_${mealPlan.meal_plan_type}`}
                              >
                                {mealPlan.meal_plan_type}
                              </label>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="mb-10">
                  <table className={styles.table}>
                    <tbody>
                      <tr>
                        <td className={styles.headings}>Select Date Range</td>
                        <td className={styles.headings}>Start Time</td>
                        <td className={styles.headings}>End Time</td>
                        <td className={styles.headings}>Min LOS</td>
                      </tr>
                      <tr>
                        <td className={styles.content}>
                          <DateRangePicker
                            onChange={(start, end) => {
                              setNewPromotionData({
                                ...newPromotionData,
                                start_date: end ? start : new Date(),
                                end_date: end ? end : new Date(),
                              });
                            }}
                          />
                        </td>
                        <td className={styles.content}>
                          <TimePicker
                            value={newPromotionData?.start_time}
                            onChange={(time) => {
                              setNewPromotionData({
                                ...newPromotionData,
                                start_time: time,
                              });
                            }}
                          />
                        </td>
                        <td className={styles.content}>
                          <TimePicker
                            value={newPromotionData?.end_time}
                            onChange={(time) => {
                              setNewPromotionData({
                                ...newPromotionData,
                                end_time: time,
                              });
                            }}
                          />
                        </td>
                        <td className={styles.content}>
                          <div style={{ width: "100px" }}>
                            <PrimaryInput
                              onChange={(e) =>
                                setNewPromotionData({
                                  ...newPromotionData,
                                  type: "percentage",
                                  all_conditions: {
                                    ...newPromotionData.all_conditions,
                                    minLos: e.target.value,
                                  },
                                })
                              }
                              value={newPromotionData.minLos}
                              type="number"
                              size={"medium"}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mb-5">
                  <div>
                    <table className={styles.table}>
                      <tbody>
                        <tr>
                          <td className={styles.headings}>Discount Method</td>
                          {newPromotionData.method === "code" && (
                            <td className={styles.headings}>Discount Code</td>
                          )}
                          <td className={styles.headings}>Discount Type</td>
                          <td className={styles.headings}>Discount</td>
                        </tr>
                        <tr>
                          <td className={styles.content}>
                            <div className="flex flex_gap_5">
                              <input
                                checked={newPromotionData.method === "code"}
                                onChange={() =>
                                  setNewPromotionData({
                                    ...newPromotionData,
                                    method: "code",
                                  })
                                }
                                type="radio"
                                className="cursor-pointer"
                                id={"discountCode"}
                              />
                              <label
                                className="cursor-pointer"
                                htmlFor={"discountCode"}
                              >
                                Code
                              </label>
                            </div>

                            <div className="flex flex_gap_5">
                              <input
                                checked={
                                  newPromotionData.method === "automatic"
                                }
                                onChange={() =>
                                  setNewPromotionData({
                                    ...newPromotionData,
                                    method: "automatic",
                                  })
                                }
                                type="radio"
                                className="cursor-pointer"
                                id={"automaticDiscount"}
                              />
                              <label
                                className="cursor-pointer"
                                htmlFor={"automaticDiscount"}
                              >
                                Automatic
                              </label>
                            </div>
                          </td>
                          {newPromotionData.method === "code" && (
                            <td className={styles.content}>
                              <div style={{ width: "100px" }}>
                                <PrimaryInput
                                  onChange={(e) =>
                                    setNewPromotionData({
                                      ...newPromotionData,
                                      discount_code: e.target.value,
                                    })
                                  }
                                  value={newPromotionData.discount_code}
                                  type="input"
                                  size={"medium"}
                                />
                              </div>
                            </td>
                          )}

                          <td className={styles.content}>
                            <div className="flex flex_gap_5">
                              <input
                                checked={
                                  newPromotionData.all_conditions.type ===
                                  "percentage"
                                }
                                onChange={() =>
                                  setNewPromotionData({
                                    ...newPromotionData,
                                    type: "percentage",
                                    all_conditions: {
                                      ...newPromotionData.all_conditions,
                                      type: "percentage",
                                    },
                                  })
                                }
                                id={"discount"}
                                type="radio"
                              />
                              <label htmlFor="">Percentage</label>
                            </div>
                            <div className="flex flex_gap_5">
                              <input
                                checked={
                                  newPromotionData.all_conditions.type ===
                                  "fixed"
                                }
                                onChange={() =>
                                  setNewPromotionData({
                                    ...newPromotionData,
                                    type: "fixed",
                                    all_conditions: {
                                      ...newPromotionData.all_conditions,
                                      type: "fixed",
                                    },
                                  })
                                }
                                type="radio"
                              />
                              <label htmlFor="">Fixed</label>
                            </div>
                          </td>
                          <td className={styles.content}>
                            <div style={{ width: "100px" }}>
                              <PrimaryInput
                                onChange={(e) =>
                                  setNewPromotionData({
                                    ...newPromotionData,
                                    amount: e.target.value,
                                  })
                                }
                                value={newPromotionData.amount}
                                type="input"
                                size={"medium"}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mb-5">
                  <h6>
                    Which day(s) of the week would you like to include in this
                    promotion?
                  </h6>
                  <DaySelectionWeekDays onSaveDates={handleStayDatesNew} />
                </div>

                <div className="mb-5">
                  <div>
                    <h6>Promotion name</h6>

                    <PrimaryInput
                      value={newPromotionData?.all_conditions?.promotion_name}
                      onChange={(e) =>
                        setNewPromotionData({
                          ...newPromotionData,
                          all_conditions: {
                            ...newPromotionData.all_conditions,
                            promotion_name: e.target.value,
                          },
                        })
                      }
                      size={"large"}
                      type="text"
                      style={{
                        width: "300px",
                        height: "40px",
                        padding: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* {button container} */}
          <div className="mt-10 flex flex_gap_10">
            <BgThemeButton onClick={postData}>Create</BgThemeButton>
            <BgOutlineBlackButton onClick={() => setShow(false)}>
              Cancel
            </BgOutlineBlackButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWebsiteDiscount;
