import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../UI/Loader/Loader";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useLocation } from "react-router-dom";
import styles from "./createContract.module.scss";
import { BgThemeButton } from "../../UI/Buttons";
import SearchComponent from "../SearchComponent/SearchComponent";
import SelectDateRange from "../SelectDateRange/SelectDateRange";
import { SvgGoBack } from "../../../assets/svgIcons";

const AgentCreateContract = () => {
  const popupStyles = {
    container: {
      width: "700px",
      borderRadius: "8px",
      background: "#fff",
      border: " 0.5px solid #ccc",
    },
    containerChild: {
      padding: "10px",
    },
    table: {
      width: "70%",
      textAlign: "left",
      borderSpacing: "0px 5px",
      marginTop: "10px",
    },
    tableBig: {
      width: "100%",
      textAlign: "left",
      borderSpacing: "0px 5px",
      marginTop: "10px",
    },
    popupHeadings: {
      color: "#333",
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: "600",
      margin: "10px 0",
    },
    categoryHeading: {
      color: "#333",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "600",
      margin: "10px 0 ",
    },
  };
  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [availableRooms, setAvailableRooms] = useState({});
  const [dateRange, setDateRange] = useState({
    from_date: "",
    to_date: "",
  });
  const [checkedRooms, setCheckedRooms] = useState([]);
  const fetchContractPageData = async () => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/property/rates/${property_id.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  const [data, setData] = useState([]);
  const [holdRooms, setHoldRooms] = useState({});

  const checkAvailability = async (id, roomName) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${dateRange.from_date}&check_out=${dateRange.to_date}&room_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAvailableRooms((prev) => ({
        ...prev,
        [roomName]: data.available_rooms,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryCheckboxChange = (roomName, id) => {
    if (selectedAgent.reference_id === "") {
      showErrorToast("Please select the Agent");
      return;
    }
    if (dateRange.from_date === "" || dateRange.to_date === "") {
      showErrorToast("Please select the Date Range");
      return;
    }
  
    if (checkedRooms.includes(roomName)) {
      setCheckedRooms(checkedRooms.filter((item) => item !== roomName));
      setData(initialData);
      setHoldRooms((prev) => {
        const updatedHoldRooms = { ...prev };
        delete updatedHoldRooms[roomName];
        return updatedHoldRooms;
      });
    } else {
      setCheckedRooms([...checkedRooms, roomName]);
      checkAvailability(id, roomName);
      setHoldRooms((prev) => ({
        ...prev,
        [roomName]: 0,
      }));
    }
  };

  const handleInputChange = (roomIndex, rateIndex, field, value) => {
    const updatedData = [...data];
    updatedData[roomIndex].rates[rateIndex][field] = value;
    setData(updatedData);
  };
  const handleHoldRoomsChange = (roomName, value) => {
    const available = availableRooms[roomName];
    if (value > available) {
      showErrorToast(`Hold rooms cannot exceed available rooms (${available})`);
      return;
    }
    setHoldRooms((prev) => ({
      ...prev,
      [roomName]: value,
    }));
  };

  const [releaseRooms, setReleaseRooms] = useState([
    { release: "D-7", amount: "" },
    { release: "D-5", amount: "" },
    { release: "D-3", amount: "" },
  ]);
  const releaseOptionsPercent = [
    { label: "select", value: "" },
    { label: "100%", value: "100" },
    { label: "75%", value: "75" },
    { label: "50%", value: "50" },
    { label: "25%", value: "25" },
    { label: "0%", value: "0" },
  ];

  const calculateTotal = () => {
    return releaseRooms.reduce((total, room) => {
      return total + (parseInt(room.amount) || 0);
    }, 0);
  };
  const handleSelectChange = (index, value) => {
    const updatedReleaseRooms = [...releaseRooms];
    updatedReleaseRooms[index].amount = value;
    setReleaseRooms(updatedReleaseRooms);
  };

  const currentTotal = calculateTotal();

  // agent and date range part

  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({
    reference_id: "",
    reference_person_name: "",
    reference_person_phone: "",
  });
  const getSelectedItem = (data) => {
    setSelectedAgent({
      reference_id: data.id,
      reference_person_name: data.agent_name,
      reference_person_phone: data.agent_phone,
    });
  };
  const handleDateChange = (form_date, to_date) => {
    setDateRange({
      from_date: form_date,
      to_date: to_date,
    });
  };
  const fetchAgents = async () => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };
  useEffect(() => {
    if (property_id?.id) {
      Promise.all([fetchContractPageData(), fetchAgents()])
        .then(([roomCategoriesData, agentsData]) => {
          if (roomCategoriesData.message === "Unauthorised.") {
            // navigate("/");
          } else {
            setData(roomCategoriesData.rooms);
            setInitialData(roomCategoriesData.rooms);
          }

          setAgents(agentsData?.data ? agentsData?.data : []);
          setLoader(false);
        })
        .catch((error) => console.error(error));
    }
  }, [property_id?.id]);

  console.log(releaseRooms,"hii")
  const handleCreateContract = async () => {
    const hasEmptyValues = Object.values(holdRooms).some(
        (value) => isNaN(value) || value === 0 || value === ""
      );
    if (hasEmptyValues) {
      showErrorToast("Please enter hold rooms values");
      return;
    }

    const totalHoldRooms = Object.values(holdRooms).reduce((sum, value) => sum + value, 0);

    const roomCategories = data
      .filter((room) => checkedRooms.includes(room.room_name))
      .map((room) => ({
        room_id: room.id,
        room_name: room.room_name,
        hold_rooms: holdRooms[room.room_name],
      }));

    const rates = data
      .filter((room) => checkedRooms.includes(room.room_name))
      .map((room) => {
        return room.rates.map((rate) => ({
          room_id: room.id,
          room_name: room.room_name,
          rate_plan_id: rate.id,
          rate_plan_type: rate.rate_plan_type,
          rate_plan_name: rate.rate_plan_name,
          rate_per_night: rate.rate_per_night,
          extra_adult: rate.extra_adult,
          child_with_Bed: rate.child_with_bed,
          child_without_Bed: rate.child_without_bed,
        }));
      })
      .flat();


  const totalReleasePercentage = releaseRooms.reduce((sum ,room)=>sum + (room.amount ? +room.amount : 0),0)
   
  if (totalReleasePercentage !== 100){
   showErrorToast("Release percentage should be 100%")
   return
  }




    const postData = {
      requestType: "agent_contract",
      total_hold_rooms: totalHoldRooms,
      from_date: dateRange.from_date,
      to_date: dateRange.to_date,
      hold_type: "automatic",
      reference_type: "agent",
      reference_id: selectedAgent.reference_id,
      reference_person_name: selectedAgent.reference_person_name,
      reference_person_phone: selectedAgent.reference_person_phone,
      release_type: "auto_release",
      release_label: releaseRooms.map((room) => room.release),
      release_percent: releaseRooms.map((room) => room.amount || 0),
      room_categories: roomCategories,
      rates: rates,
    };

    try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/create/agent/contract/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(postData),
          }
        );
      
        const data = await response.json();
      
        if (response.ok) {
          showSuccessToast(data.message);
          navigate("/agent-contracts");
        } else {
          showErrorToast(data.error || "Failed to create contract");
        }
      } catch (error) {
        console.error(error);
        showErrorToast("Failed to create contract");
      }
  };

  return (
    <div>
      <div className="flex flex_gap_15 align_items_center">
        <span className="pointer"
          onClick={() => navigate(-1)}
          
        >
         <SvgGoBack/>
        </span>

        <div className="room-type-heading">Create Contract </div>
      </div>
      {/* <div> */}
      <div className={styles.createContractContainer}>
        <div
          className="flex flex_gap_20 align_items_center "
          style={{ width: "500px" }}
        >
          <div style={{ width: "50%" }}>
            <SearchComponent
              searchArray={agents}
              keyName={"agent_name"}
              searchFilterBy={"agent_name"}
              getSelectedItem={getSelectedItem}
              titleName="Select Agent"
            />
          </div>

          <div style={{ width: "50%" }}>
            <label>Select Date Range</label>
            <div style={{ marginTop: "5px" }}>
              <SelectDateRange
                dataFromChild={handleDateChange}
                minDate={new Date(propertyCurrentDate)}
              />
            </div>
          </div>
        </div>

        {data &&
          data.length > 0 &&
          data?.map((item, roomIndex) => (
            <div key={item.id} className={styles.roomTypeContainer}>
              <div
                className="flex align_items_center justify_content_between"
                style={{ margin: "10px 0" }}
              >
                <div className={styles.checkboxLabelContainer}>
                  <label className={`flex flex_gap_10 `}>
                    <span>
                      <input
                        type="checkbox"
                        checked={checkedRooms.includes(item.room_name)}
                        onChange={() =>
                          handleCategoryCheckboxChange(item.room_name, item.id)
                        }
                      />
                    </span>
                    {item.room_name}
                  </label>
                </div>

                {checkedRooms.includes(item.room_name) && (
                  <div
                    className={`flex align_items_center flex_gap_20 ${styles.availableRoomsContainer}`}
                  >
                    <span className={styles.availableRoomsChild}>
                      <label>Available:</label>
                      <input
                        value={availableRooms[item.room_name]}
                        disabled
                      />{" "}
                    </span>
                    <span className={styles.availableRoomsChild}>
                      <label>Hold Rooms :</label>
                      <input
                        type="number"
                        min="0"
                        max={availableRooms[item.room_name]}
                        value={holdRooms[item.room_name] }
                        onChange={(e) =>
                          handleHoldRoomsChange(
                            item.room_name,
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </span>
                  </div>
                )}
              </div>

              {checkedRooms.includes(item.room_name) && (
                <>
                  {item.rates.map((mealPlan, mealPlanIndex) => (
                    <div
                      key={mealPlanIndex}
                      className={styles.mealPlanContainer}
                    >
                      <table>
                        <tbody>
                          <tr style={{ borderBottom: "1px solid #ddd" }}>
                            <td className={styles.tableMealPlanName}>
                              {mealPlan.rate_plan_name}
                            </td>
                            <td>
                              Room Rate:
                              <br />
                              <input
                                type="number"
                                min="0"
                                value={mealPlan.rate_per_night || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    roomIndex,
                                    mealPlanIndex,
                                    "rate_per_night",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              Extra Adult:
                              <input
                                type="number"
                                min="0"
                                value={mealPlan.extra_adult || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    roomIndex,
                                    mealPlanIndex,
                                    "extra_adult",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              Child (with bed):
                              <input
                                type="number"
                                min="0"
                                value={mealPlan.child_with_bed || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    roomIndex,
                                    mealPlanIndex,
                                    "child_with_bed",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              Child (without bed):
                              <input
                                type="number"
                                min="0"
                                value={mealPlan.child_without_bed || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    roomIndex,
                                    mealPlanIndex,
                                    "child_without_bed",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}

        <hr style={{ margin: "20px 0" }} />
        <div
          className={`flex flex_gap_10 align_items_center ${styles.releaseRestrictionContainer}`}
        >
          <h4>Release Restriction :</h4>

          {releaseRooms.map((releaseRoom, index) => (
            <div
              key={releaseRoom.release}
              className="flex flex_gap_5 align_items_center"
            >
              <label style={{ fontWeight: "600", fontSize: "12px" }}>
                {releaseRoom.release}
              </label>
              <select
                key={index}
                value={releaseRoom.amount}
                style={popupStyles.agent_hold_rooms_popup_select}
                className="agent_hold_rooms_popup_select"
                onChange={(e) => handleSelectChange(index, e.target.value)}
              >
                {releaseOptionsPercent.map((item, optionIndex) => {
                  const optionValue = parseInt(item.value);
                  const isDisabled =
                    currentTotal -
                      (parseInt(releaseRoom.amount) || 0) +
                      optionValue >
                    100;

                  return (
                    <option
                      key={optionIndex}
                      value={item.value}
                      disabled={isDisabled}
                    >
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
          ))}
        </div>

        <BgThemeButton
          children={"Create"}
          style={{ margin: "20px 0" }}
          onClick={handleCreateContract}
        />
      </div>
      {/* </div> */}
    </div>
  );
};

export default AgentCreateContract;
