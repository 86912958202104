import React, { useState, useEffect, useCallback } from "react";
import SidebarInnerLists from "../../Components/CustomComponents/SidebarInnerLists";
import { websites_inner_lists } from "../../assets/constant";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import styles from "./websites.module.scss";
import { BgThemeButton } from "../../Components/UI/Buttons";
import { useSelector } from "react-redux";
import { getBaseUrl, getDateByMonth } from "../../utils/utils";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { SvgDelete, SvgEdit } from "../../assets/svgIcons";
import CreateWebsiteDiscount from "./CreateWebsiteDiscount";
import ToggleSwitch from "../../Components/NewCustomComp/Switch/ToggleSwitch";
import NoDataFound from "../../Components/CustomComponents/NoDataFound";
import Loader from "../../Components/UI/Loader/Loader";

const WebsitesDiscounts = () => {
  const baseUrl = getBaseUrl();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [rooms, setRooms] = useState([]);
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value)?.id;

  useEffect(() => {
    if (property_id) {
      fetchRooms();
    }
  }, [property_id]);

  const fetchRooms = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/rooms/${property_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json().then((data) => {
        if (data.success) {
          setRooms(data.rooms);
          setNewPromotionData((prevData) => ({
            ...prevData,
            all_conditions: {
              ...prevData.all_conditions,
              selected_rooms: data.rooms.map((room) => room.id),
            },
          }));
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [newPromotionData, setNewPromotionData] = useState({
    name: "PMS Discounts",
    type: "percentage",
    method: "code",
    discount_code: "",
    amount: "",
    start_date: "",
    end_date: "",
    start_time: "00:00:00",
    end_time: "00:00:00",
    status: "enable",
    all_conditions: {
      type: "percentage",
      rate_plans: "all",
      selected_rate_plans: [],
      rooms: "all",
      selected_rooms: [],
      discount: "",
      stay_dates: [],
      promotion_name: "",
      booking_period: "days",
      booking_period_value: "",
      certain_hours: "",
    },
  });

  const key = JSON.parse(localStorage.getItem("selectedProperty"));

  // ========== get booking discounts =======////

  const [discounts, setDiscounts] = useState([]);

  const fetchDiscounts = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/discounts/list/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
            key: key.token,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setDiscounts(data.discounts);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchDiscounts();
    }
  }, [property_id]);

  const [show, setShow] = useState(false);

  const handleToggle = async (isOn, item) => {
    const postData = {
      ...item,
      status: isOn ? "enable" : "disable",
    };

    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}api/v1/booking-engine/discounts/update/${item.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
            key: key.token,
          },
          method: "POST",
          body: JSON.stringify({
            ...postData,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setShow(false);
        fetchDiscounts();
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div >
     

      {show && (
        <>
          <div className="global_popup_container_background">
            <div className="global_popup_container" style={{ width: "700px" }}>
              <CreateWebsiteDiscount
                show={show}
                setShow={setShow}
                fetchDiscounts={fetchDiscounts}
                rooms={rooms}
                newPromotionData={newPromotionData}
                setNewPromotionData={setNewPromotionData}
              />
            </div>
          </div>
        </>
      )}

      <div className={styles.website_discount_container}>
        <div className="room-types-full-container-div mt-20 flex justify-content-between">
          <div className="room-type-heading">Added Discounts</div>
          <BgThemeButton onClick={() => setShow(true)}>
            Create Discount  
          </BgThemeButton>
        </div>
        <div className="room-types-full-container-div mt-20">
          {/* <CustomIndex   */}
          {loader ? (
            <Loader />
          ) : (
            <>
              {discounts.length > 0 ? (
                <>
                  <CustomShopifyIndexTable
                    showCheckbox={false}
                    headings={[
                      "Discount Name",
                      "Discount Type",
                      "Discount Method",
                      "Discount Code",
                      "Amount",
                      "Rooms",
                      "Meal Plans",
                      "Start Date",
                      "End Date",
                      "Status",
                    ]}
                  >
                    {discounts?.map((item, index) => {
                      const rooms_selected =
                        item.all_conditions.rooms === "all"
                          ? "All"
                          : item.all_conditions.selected_rooms.map((roomId) => {
                              const room = rooms.find((r) => r.id === roomId); // Find the room object by ID
                              return room ? <p>{room.room_name}</p> : "Unknown"; // Correctly accessing room_name
                            });

                      const meal_plans =
                        item.all_conditions.rate_plans === "all"
                          ? "All"
                          : item.all_conditions.selected_rate_plans
                              .map((plan) => plan)
                              .join(", ");

                      return (
                        <CustomShopifyIndexTableRow
                          key={index}
                          style={{
                            borderBottom:
                              index === discounts?.length - 1
                                ? "none"
                                : "1px solid #E8E8E8",
                          }}
                        >
                          <CustomShopifyIndexTableCell>
                            {item?.name}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {item?.type}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {item?.method ? item?.method : "NA"}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {item?.discount_code ? item?.discount_code : "NA"}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {item?.amount}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            <div className={styles["rooms-selected"]}>
                              {rooms_selected}
                            </div>
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {meal_plans}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {getDateByMonth(item?.start_date)}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {getDateByMonth(item?.end_date)}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            <span className="flex flex_gap_15 align_items_center">
                              <span>
                                {/* <SvgEdit /> */}
                                <ToggleSwitch
                                  size={"small"}
                                  isOn={
                                    item?.status === "enable" ? true : false
                                  }
                                  handleToggle={(isOn) =>
                                    handleToggle(isOn, item)
                                  }
                                />
                              </span>
                              <span>
                                <SvgDelete />
                              </span>
                            </span>
                          </CustomShopifyIndexTableCell>
                        </CustomShopifyIndexTableRow>
                      );
                    })}
                  </CustomShopifyIndexTable>
                </>
              ) : (
                <div className="flex justify_content_center align_items_center w-100 h-100">
                  <NoDataFound />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebsitesDiscounts;
