import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";

const SettingsContent = () => {
  const activeApp = localStorage.getItem("activeApp");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const property_id = useSelector((state) => state.property.value);
  const location = useLocation();
  
  useEffect(() => {
    const selectedProperty = JSON.parse(
      localStorage.getItem("selectedProperty")
    );
    setSelectedProperty(selectedProperty);
  }, [property_id?.id]);

  
  const booking_engine_tabs = [
    {
      title: "Profile",
      link: "profile",
    },

    {
      title: "Brand",
      link: "brand",
    },
  ];

  const channel_manager_tabs = [
    {
      title: "Profile",
      link: "profile",
    },

    {
      title: "Brand",
      link: "brand",
    },
  ];

  const pma_tabs = [
    {
      title: "Profile",
      link: "profile",
    },
    // {
    //   title: "Location",
    //   link: "location",
    // },
    {
      title: "Brand",
      link: "brand",
    },
    {
      title: "Distance",
      link: "distance",
    },
    {
      title: "Tax",
      link: "tax",
    },
    {
      title: "Staffs",
      link: "staffs",
    },
    // {
    //   title: "Roles & Permissions",
    //   link: "roles-and-permission",
    // },
    {
      title: "Activity Logs",
      link: "activity-logs",
    },
    {
      title: "OTA Mapping",
      link: "ota-mapping",
    },
    {
      title: "Folio Series",
      link: "folio-series",
    },
    {
      title: "Payment Gateways",
      link: "folio-gateways",
    },
    {
      title: "Privacy Policy",
      link: "privacy-policy",
    },
    {
      title: "Domains",
      link: "property-domain",
    },
    {
      title: "Add-Ons",
      link: "add-ons",
    },
    {
      title: "Flexi-Pay",
      link: "flexi-pay",
    },
  ];

  const tabs =
    activeApp === "booking_engine"
      ? booking_engine_tabs
      : activeApp === "channel_manager"
      ? channel_manager_tabs
      : pma_tabs;

  const activeTab = tabs.findIndex((tab) =>
    location.pathname.includes(tab.link)
  );

  return (
    <>
      <div className="settings_sec">
        <div className="">
          <div className="settings_main">
           
            <div className="settings_sec_right" style={{ width: "100%" }}>
              <div className="custom_tab_container">
                <div className="custom_tab_header">
                  {tabs.map((tab, index) => (
                    
                    <Link
                      to={`/settings/${tab.link}`}
                      className={`tab_item ${
                        index === activeTab ? "active" : ""
                      }`}
                      key={index}
                    >
                      {tab.title}
                    </Link>
                  ))}
                </div>
                <div
                  className="custom-tab-content"
                  style={{ width: "70%", margin: "auto" }}
                >
                  <Outlet />
                  {/* {tabs[activeTab].content} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsContent;
