import React, { useState } from "react";
import styles from "./selectApp.module.scss";
import SelectAppOptions from "../BookingEngine/SelectAppOptions";

const SwitchApp = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className={styles.container}>
        <button onClick={toggleDropdown} className={styles.switchAppButton}>
          Switch App
        </button>
      </div>

      {isOpen && (
        <>
          <div className={styles.selectPropertyContainer}>
            <SelectAppOptions setIsOpen={setIsOpen} />
          </div>
        </>
      )}
    </>
  );
};

export default SwitchApp;
