import React, { useEffect, useRef } from "react";
import { useState} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SvgCancel, SvgUpFilled } from "../../assets/svgIcons";
import { saveActiveProperty } from "../../reduxToolkit/Slice/propertyslice";
import { setProperties } from "../../reduxToolkit/Slice/propertiesSlices";
import { getActiveApp } from "../../utils/utils";

const SwitchPropertyPopup = () => {
  const [show, setShow] = useState(false);
  const activeApp = getActiveApp();
  const hotelOptionsRef = useRef(null);
  const [hotelOptionsIsOpen, setHotelOptionsIsOpen] = useState(false);
  const [allProperties, setAllProperties] = useState([]);
  const selectedProperty = JSON.parse(localStorage.getItem("selectedProperty"));
  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [selectedHotelOption, setSelectedHotelOption] =
    useState(selectedProperty);

  const dispatch = useDispatch();

//   useEffect(() => {
//     if (selectedHotelOption) {
//       dispatch(saveActiveProperty(selectedHotelOption));
//       localStorage.setItem(
//         "selectedProperty",
//         JSON.stringify(selectedHotelOption)
//       );
//     }
//   }, [selectedHotelOption]);

  const handleClickOutside = (event) => {
    if (
      hotelOptionsRef.current &&
      !hotelOptionsRef.current.contains(event.target)
    ) {
      setHotelOptionsIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchInfo = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user-profile?active_app=${activeApp}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 401 || response.status === 500) {
        localStorage.clear();
        return;
      }

      const data = await response.json();
      setAllProperties(data.data.properties);
      localStorage.setItem(
        "permissions",
        JSON.stringify(data.data.permissions)
      );
      localStorage.setItem("user", JSON.stringify(data?.data?.user));
      if (data?.data?.properties?.length > 0) {
        if (selectedProperty) {
          setSelectedHotelOption(selectedProperty);
        //   dispatch(saveActiveProperty(selectedHotelOption));
        } else {
          setSelectedHotelOption(data?.data?.properties[0]);
        }
        dispatch(setProperties(data?.data?.properties));
      } else if (data?.data?.properties.length === 0) {
        navigate("/nopropertypage");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const toggleHotelOptionsDropdown = () => {
    setHotelOptionsIsOpen(!hotelOptionsIsOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedHotelOption(option);
    setHotelOptionsIsOpen(false);
    dispatch(saveActiveProperty(option));
      localStorage.setItem(
        "selectedProperty",
        JSON.stringify(option)
      );
    setShow(false)
  };

  return (
    <div>
      <div onClick={() => setShow(true)}>Switch Property</div>

      {show ? (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container">
              <div className="switch_property_popup_heading align_items_center">
                <h1>Switch Property</h1>{" "}
                <span className="pointer" onClick={() => setShow(false)}>
                  <SvgCancel color="#333" height={"16px"} width={"16px"} />
                </span>
              </div>

              <div className="hotel_options_dropdown" ref={hotelOptionsRef}>
                {hotelOptionsIsOpen && (
                  <div>
                    <div className="hotel_options_dropdown_options">
                      {allProperties?.map((option, index) => (
                        <div
                          className="hotel_options_dropdown_options_child"
                          key={index}
                          onClick={() => handleOptionClick(option)}
                        >
                          {option?.property_name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div
                  className="hotel_options_dropdown_btn"
                  onClick={toggleHotelOptionsDropdown}
                >
                  <div className="truncate">
                    {selectedHotelOption?.property_name}
                  </div>
                  {hotelOptionsIsOpen ? (
                    <span style={{ transform: "rotate(180deg)" }}>
                      <SvgUpFilled />
                    </span>
                  ) : (
                    <SvgUpFilled />
                  )}
                </div>
              </div>
            </section>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SwitchPropertyPopup;
