import React from 'react'
import styles from "./NavTabs.module.scss"
import { NavLink, useLocation } from 'react-router-dom'
import icon from "../../../assets/images/icon.png"

const NavTabs = ({
  mainData,
  type
}) => {

  const location = useLocation();
  

  return (
    <div className={styles.tabsMain}>
      <div className={styles.tabsWrapper}>
        {(!type || type === "icon") && (
          <>
            {mainData.map((items, index) =>
              <div key={index} className={`${styles.tabsCards} ${styles.underline}`}>
                <NavLink to={items.to} className={({ isActive }) => ` ${isActive ? styles.underlineActive : ""} ${styles.underHover} `}>
                  {type === "icon" && (
                      <span className={`${location.pathname.includes(items.to) ? styles.btn_icon_underline : styles.btn_icon_de}`}>{items.icon}</span>
                    )}
                  {items.label}
                </NavLink>
              </div>
            )}
          </>
        )}
        {type === "button" && (
          <>
            {mainData.map((items, index) =>
              <div style={{ marginRight: "0" }} key={index} className={`${styles.tabsCards} ${styles.solidBackground}`}>
                <NavLink to={items.to} className={({ isActive }) => `${isActive ? styles.solidActive : ""} ${styles.solidHover}`}>{items.label}</NavLink>
              </div>
            )}
          </>
        )}
        {type === "btnWithIcon" && (
          <>
            {mainData.map((items, index) =>
              <div style={{ marginRight: "0" }} key={index} className={`${styles.tabsCards} ${styles.solidBackground}`}>
                <NavLink to={items.to} className={({ isActive }) => `${isActive ? styles.solidActive : ""}  ${styles.solidHover} `}>
                  <span className={`${location.pathname.includes(items.to) ? styles.btn_icon_active : styles.btn_icon_de}`}>{items.icon}</span>
                  {items.label}
                </NavLink>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default NavTabs
